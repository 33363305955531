/*
 * home.js
 * coop@arbor-iot.com
 * 2024-04-09
 * Landing page
 */
import React, { useEffect } from "react";

import { useAuth } from "../../AuthContext";

import {
  FadeIn,
  FadeInLeft,
  FadeInRight,
  FadeInBottom,
} from "./components/FadeIn";
import NavBar from "./components/NavBar";
import LogoCloud from "./components/Carousel.js";
import AvatarComp from "./components/AvatarComp.js";
import {
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Icon,
  Wrap,
  WrapItem,
  Text,
  Image,
  Center,
  Box,
  Stack,
  HStack,
  VStack,
  Button,
  Container,
  List,
  ListItem,
  ListIcon,
  Heading,
  UnorderedList,
  Tbody,
  TableContainer,
  Table,
  Tr,
  Td,
  Flex,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  ButtonGroup,
  OrderedList,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";

import { chakra, shouldForwardProp } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";

import {
  FaChartBar,
  FaClock,
  FaLinkedin,
  FaLinkedinIn,
  FaRegClock,
} from "react-icons/fa";
import { CiClock1, CiCloud } from "react-icons/ci";
import { PiChartLineThin } from "react-icons/pi";
import { FaCalendarDay, FaSquareEnvelope } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa6";

const ChakraFramerImg = chakra(motion.img, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const ChakraFramerDiv = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const Home = () => {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    // Function to handle smooth scrolling on navigation link click
    const handleNavLinkClick = (event) => {
      const headerHeight = document.querySelector("header").clientHeight;
      const targetId = event.currentTarget.getAttribute("href");
      const target = document.querySelector(targetId);

      if (target) {
        event.preventDefault();
        const targetPosition = target.getBoundingClientRect().top + 3;
        // window.pageYOffset -
        // headerHeight;

        window.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    };

    // Attach event listeners to navigation links
    const navLinks = document.querySelectorAll("nav a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavLinkClick);
    });

    // Function to handle smooth scrolling on logo click
    const handleLogoClick = (event) => {
      event.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Attach event listener to the logo
    const logo = document.querySelector(".open-logo");
    if (logo) {
      logo.addEventListener("click", handleLogoClick);
    }

    // Cleanup event listeners when the component unmounts
    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavLinkClick);
      });
      if (logo) {
        logo.removeEventListener("click", handleLogoClick);
      }
    };
  }, []);

  const StackComponent = useBreakpointValue({
    base: VStack,
    xl: HStack,
  });

  const StackComponent2 = useBreakpointValue({
    base: VStack,
    lg: HStack,
  });

  const StackComponent3 = useBreakpointValue({
    base: VStack,
    md: HStack,
  });

  const textAlign = useBreakpointValue({ base: "center", xl: "right" });

  return (
    <Container maxW="full" p={0} overflowX="hidden">
      <NavBar />
      <Container
        maxW="full"
        h={{
          base: "700px",
          sm: "1000px",
          md: "1000px",
          lg: "1300px",
          xl: "1300px",
        }}
        id="main"
        p={0}
        pt={{ md: "150px" }}
      >
        <VStack
          w="full"
          h="full"
          justifyContent="center"
          textAlign="center"
          overflow="hidden"
          px="10px"
        >
          <Box as="span" position="relative">
            <Box
              position="absolute"
              width="105%"
              height="20%"
              top="55%"
              left="-2.5%"
              zIndex="-1"
              backgroundColor="oai.yellow"
            />
            <Text
              as="span"
              position="relative"
              zIndex="8"
              maxW="700px"
              fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}
              fontWeight="bold"
              bgClip="text"
              color="black"
              fontFamily="niramit"
            >
              Reimagine Construction
            </Text>
          </Box>
          <Box as="span" position="relative">
            <Box
              position="absolute"
              width="105%"
              height="20%"
              top="55%"
              left="-2.5%"
              zIndex="-1"
              backgroundColor="oai.yellow"
            />
            <Text
              as="span"
              position="relative"
              zIndex="8"
              maxW="700px"
              fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}
              fontWeight="bold"
              bgClip="text"
              color="black"
              fontFamily="niramit"
            >
              with AI-Driven Systems
            </Text>
          </Box>
          <Text
            zIndex="8"
            maxW="700px"
            fontSize={{ base: "lg", sm: "xl", md: "xl", lg: "2xl" }}
            fontWeight="normal"
            bgGradient={`linear(180deg, ${"oai.grey"}, ${"oai.white"})`}
            bgClip="text"
            color="oai.darkgrey"
            fontFamily="niramit"
            py="10px"
          >
            Real-time concrete strength and temperature monitoring to ensure
            quality, safety, and efficiency on your construction sites.
          </Text>
          <Link href="mailto:hello@opusense.com">
            <Button
              h={{ base: "50px", sm: "70px" }}
              w={{ base: "250px", sm: "300px" }}
              fontFamily="niramit"
              fontSize={{ base: "lg", sm: "xl", md: "xl", lg: "xl" }}
              p={{ base: 3 }}
              my={3}
              borderRadius={{ base: "15px", sm: "20px" }}
              color={"oai.yellow"}
              backgroundColor={"oai.black"}
              boxShadow="xl"
              _hover={{
                pt: 0,
              }}
              transition="padding-top 0.3s ease, box-shadow 0.3s ease"
              _active={{}}
              _focus={{
                pt: 0,
              }}
            >
              Get Started Today!
            </Button>
          </Link>

          <Box position="relative" h={{ lg: "800px" }}>
            <Image
              maxW="1100px"
              w={{
                base: "300px",
                sm: "450px",
                md: "600px",
                lg: "700px",
                xl: "900px",
              }}
              overflow="hidden"
              src="app-mockup.png"
              alt="app mockup"
              border="3px solid"
              borderColor="oai.black"
              borderRadius="lg"
              my={{ lg: "70px" }}
              objectPosition="center"
            />
            <Image
              maxW="1100px"
              w={{
                base: "350px",
                sm: "450px",
                md: "600px",
                lg: "700px",
                xl: "900px",
              }}
              overflow="hidden"
              src="V2-grey-angled.png"
              alt="Node Render"
              position="absolute"
              top={{
                base: "100px",
                sm: "150px",
                md: "200px",
                lg: "300px",
                xl: "400px",
              }}
              left={{
                base: "40px",
                sm: "100px",
                md: "200px",
                lg: "200px",
                xl: "300px",
              }}
            />
          </Box>
        </VStack>
      </Container>
      <Box width="100%" overflow="hidden" lineHeight={0} mt="100px">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ display: "block", width: "100%", height: "100px" }}
        >
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                style={{
                  stopColor: "rgba(244, 190, 29, 0.15)",
                  stopOpacity: 1,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: "rgba(244, 190, 29, 0.15)",
                  stopOpacity: 1,
                }}
              />
            </linearGradient>
          </defs>
          <path
            d="M0,0 C150,100 350,0 500,100"
            style={{
              fill: "none",
              stroke: "rgb(244, 190, 29)", // Border color
              strokeWidth: 2, // Border width
            }}
          />
          <path
            d="M0,0 C150,100 350,0 500,100 L500,150 L0,150 Z"
            style={{
              fill: "url(#gradient)",
            }}
          />
        </svg>
      </Box>
      <Container
        // h={{base: lg: "1000px" }}
        // h={{
        //   base: "1550px",
        //   sm: "1400px",
        //   md: "1200px",
        //   lg: "1200px",
        //   xl: "800px",
        // }}
        mb={{ base: "120px", lg: "200px" }}
        maxW="full"
        id="intro"
        p={0}
      >
        {/* <VStack h="full" justifyContent="center" overflow="hidden"> */}
        <Box
          w="full"
          background="linear-gradient(to bottom, rgba(244, 190, 29, 0.15), white)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          px="20px"
        >
          <StackComponent mt="100px">
            <VStack
              maxW={{ base: "480px", md: "600px" }}
              h={{ base: "500px", sm: "300px" }}
              px={{ base: "5px", md: "0px" }}
              mr={{ base: "0px", md: "30px" }}
              fontSize={{ base: "lg", sm: "xl", md: "xl", lg: "xl" }}
              fontWeight="normal"
              fontFamily="niramit"
              color="oai.darkgrey"
              align={{ base: "center", xl: "flex-end" }}
              justify="center"
              spacing="5px"
            >
              <Box
                maxW="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <Text
                  as="span"
                  fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}
                  fontWeight="bold"
                  fontFamily="niramit"
                  color="black"
                  mb="20px"
                >
                  Transform your concrete pouring process
                </Text>
              </Box>
              <Box textAlign={textAlign}>
                <Text as="span" fontWeight="bold">
                  Opusense AI is your trusted partner in cutting-edge concrete
                  monitoring solutions.
                </Text>{" "}
                <Text as="span">
                  Our AI-driven technology ensures accurate, real-time data on
                  concrete strength and temperature, helping you make informed
                  decisions and maintain the highest standards of quality and
                  safety.
                </Text>
              </Box>
            </VStack>
            <Box
              borderWidth="1.5px"
              borderColor="oai.yellow"
              borderRadius="10px"
              padding="20px"
              maxW="700px"
              background="white"
              fontSize={{ lg: "xl" }}
              fontFamily="niramit"
              overflowX="auto"
              display={{ base: "none", md: "flex" }}
            >
              <TableContainer maxWidth="700px">
                <Table size="lg" variant="simple" h="200px">
                  <Tbody whiteSpace="normal" h="20px">
                    <Tr>
                      <Td>
                        <VStack textAlign="center" justifyContent="center">
                          <CiClock1
                            size="60px"
                            color="oai.darkgrey"
                            opacity="0.8"
                          />
                          <Text>Real-Time Monitoring</Text>
                        </VStack>
                      </Td>
                      <Td>
                        Utilize our advanced sensors and AI-powered software to
                        instantly access real-time accurate data on concrete
                        strength and temperature.
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <VStack textAlign="center" justifyContent="center">
                          <PiChartLineThin
                            size="60px"
                            color="oai.darkgrey"
                            opacity="0.8"
                          />
                          <Text>Accurate and Reliable Data</Text>
                        </VStack>
                      </Td>
                      <Td>
                        Eliminate delays caused by traditional testing methods
                        with our precise, real-time data collection.
                      </Td>
                    </Tr>
                    <Tr>
                      <Td border="none">
                        <VStack textAlign="center" justifyContent="center">
                          <CiCloud
                            size="60px"
                            color="oai.darkgrey"
                            opacity="0.8"
                          />
                          <Text>Remote Management</Text>
                        </VStack>
                      </Td>
                      <Td border="none">
                        Monitor your data from multiple projects remotely with
                        our intuitive cloud-based dashboard.
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              borderWidth="1.5px"
              borderColor="oai.yellow"
              borderRadius="10px"
              padding="20px"
              maxW="600px"
              background="white"
              fontSize={{ lg: "xl" }}
              fontFamily="niramit"
              overflowX="auto"
              display={{ base: "flex", md: "none" }}
            >
              <TableContainer maxWidth="700px">
                <Table size="lg" variant="simple" h="200px">
                  <Tbody whiteSpace="normal" h="20px">
                    <Tr>
                      <Td>
                        <VStack textAlign="center" justifyContent="center">
                          <CiClock1
                            size="60px"
                            color="oai.darkgrey"
                            opacity="0.8"
                          />
                          <Text>Real-Time Monitoring</Text>
                          <Text>
                            Utilize our advanced sensors and AI-powered software
                            to instantly access real-time accurate data on
                            concrete strength and temperature.
                          </Text>
                        </VStack>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <VStack textAlign="center" justifyContent="center">
                          <PiChartLineThin
                            size="60px"
                            color="oai.darkgrey"
                            opacity="0.8"
                          />
                          <Text>Accurate and Reliable Data</Text>
                          <Text>
                            Eliminate delays caused by traditional testing
                            methods with our precise, real-time data collection.
                          </Text>
                        </VStack>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td border="none">
                        <VStack textAlign="center" justifyContent="center">
                          <CiCloud
                            size="60px"
                            color="oai.darkgrey"
                            opacity="0.8"
                          />
                          <Text>Remote Management</Text>
                          <Text>
                            Monitor your data from multiple projects remotely
                            with our intuitive cloud-based dashboard.
                          </Text>
                        </VStack>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </StackComponent>
        </Box>
      </Container>
      <Container
        maxW="full"
        id="products"
        // h={{ base: "2200px", lg: "1200px" }}
        mb={{ base: "100px", sm: "120px", lg: "150px" }}
      >
        <VStack
          maxW="full"
          align="center"
          justifyContent="center"
          textAlign="center"
        >
          <Box as="span" position="relative">
            <Box
              px={1}
              position="absolute"
              width="105%"
              height="20%"
              top="55%"
              left="-2.5%"
              zIndex="-1"
              backgroundColor="oai.yellow"
            />
            <Text
              fontSize={{ base: "3xl", sm: "4xl", md: "6xl", lg: "6xl" }}
              fontWeight="bold"
              fontFamily="niramit"
              color="black"
              align="center"
            >
              Products and Services
            </Text>
          </Box>
        </VStack>
        <VStack mt="40px" justifyContent="center" spacing="50px">
          <StackComponent2 spacing="50px">
            <Card maxW="500px" h={{ lg: "460px" }}>
              <CardBody>
                <Image
                  src="concrete-truck.jpg"
                  alt="Concrete truck"
                  borderRadius="lg"
                  h="200px"
                  w="460px"
                  objectFit="cover"
                />
                <Stack mt="24px" spacing="4px">
                  <Heading size="lg" mb="5px">
                    Concrete Strength Monitoring
                  </Heading>
                  <UnorderedList mt="4px">
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Strength Leaf Sensors:
                      </Text>{" "}
                      Our remote sensing technology provide accurate, in-situ
                      concrete strength data, reducing reliance on traditional
                      testing methods.
                    </ListItem>
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Standards Compliance:
                      </Text>{" "}
                      Our solutions comply with ASTM standards, ensuring
                      reliable and consistent results.
                    </ListItem>
                  </UnorderedList>
                </Stack>
              </CardBody>
            </Card>
            <Card maxW="500px" h={{ lg: "460px" }}>
              <CardBody>
                <Image
                  src="site-sun.jpg"
                  alt="Construction site with sun background"
                  borderRadius="lg"
                  h="200px"
                  w="460px"
                  objectFit="cover"
                />
                <Stack mt="24px" spacing="4px">
                  <Heading size="lg" mb="5px">
                    Temperature Monitoring
                  </Heading>
                  <UnorderedList mt="4px">
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Detailed Data:{" "}
                      </Text>{" "}
                      Monitor temperature differentials in concrete pours to
                      prevent thermal cracking and ensure proper curing.
                    </ListItem>
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Alerts and Notifications:{" "}
                      </Text>
                      Set custom thresholds and receive alerts to stay informed
                      about your project's status and critical parameters.
                    </ListItem>
                  </UnorderedList>
                </Stack>
              </CardBody>
            </Card>
          </StackComponent2>
          <StackComponent2 spacing="50px">
            <Card maxW="500px" h={{ lg: "550px" }}>
              <CardBody>
                <Image
                  src="ai-robot.jpg"
                  alt="Robot pointing"
                  borderRadius="lg"
                  h="200px"
                  w="460px"
                  objectFit="cover"
                />
                <Stack mt="24px" spacing="4px">
                  <Heading size="lg" mb="5px">
                    Voice-Activated Data Requests
                  </Heading>
                  <UnorderedList mt="4px">
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Simple Interaction:{" "}
                      </Text>
                      Just speak your request and receive the data you need
                      instantly—no need to navigate through multiple screens or
                      menus.
                    </ListItem>
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Hands-Free Convenience:{" "}
                      </Text>
                      Access critical information, such as concrete strength and
                      temperature, without interrupting your workflow.
                    </ListItem>
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Real-Time Access:{" "}
                      </Text>
                      Get real-time data through voice commands, enabling
                      quicker decisions and on-the-spot updates directly from
                      the job site.
                    </ListItem>
                  </UnorderedList>
                </Stack>
              </CardBody>
            </Card>
            <Card maxW="500px" h={{ lg: "550px" }}>
              <CardBody>
                <Image
                  src="leaf-on-stone.jpg"
                  alt="Green double couch with wooden legs"
                  borderRadius="lg"
                  h="200px"
                  w="460px"
                  objectFit="cover"
                />
                <Stack mt="24px" spacing="4px">
                  <Heading size="lg" mb="5px">
                    Smart Sensors
                  </Heading>
                  <UnorderedList mt="4px">
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Durable:{" "}
                      </Text>
                      Designed to withstand harsh conditions of construction
                      sites, ensuring reliable performance.
                    </ListItem>
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Easy Installation:{" "}
                      </Text>
                      Attach sensors to rebar and start receiving data
                      immediately on your mobile device.
                    </ListItem>
                    <ListItem>
                      <Text as="span" fontWeight="bold">
                        Fully embedded concrete monitoring (Beta):{" "}
                      </Text>
                      Fully embedded version reduces the chance of damage during
                      construction while increasing the density, providing
                      uninterrupted data collection and improved decision-making
                    </ListItem>
                  </UnorderedList>
                </Stack>
              </CardBody>
            </Card>
          </StackComponent2>
        </VStack>
      </Container>
      <Container maxW={{ base: "480px", md: "700px", xl: "1100px" }}>
        <StackComponent spacing={5}>
          <VStack>
            <Box
              flex="1"
              textAlign={{ base: "center", xl: "right" }}
              as="span"
              position="relative"
            >
              <Box
                px={1}
                position="absolute"
                width="105%"
                height="20%"
                top="54%"
                left="-2.5%"
                zIndex="-1"
                backgroundColor="oai.yellow"
              />
              <Text
                as="span"
                position="relative"
                fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
                fontWeight="bold"
                bgClip="text"
                color="black"
                fontFamily="niramit"
              >
                Automated Report
              </Text>
            </Box>
            <Box
              flex="1"
              textAlign={{ base: "center", xl: "right" }}
              as="span"
              position="relative"
            >
              <Box
                px={1}
                position="absolute"
                width="105%"
                height="20%"
                top="55%"
                left="-2.5%"
                zIndex="-1"
                backgroundColor="oai.yellow"
              />
              <Text
                as="span"
                position="relative"
                fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
                fontWeight="bold"
                bgClip="text"
                color="black"
                fontFamily="niramit"
              >
                Generation
              </Text>
            </Box>
          </VStack>
          <Box flex="1">
            <Text
              fontSize={{ base: "lg", sm: "xl", md: "xl", lg: "xl" }}
              fontWeight="normal"
              fontFamily="niramit"
              color="oai.darkgrey"
              py="10px"
            >
              Effortlessly generate detailed inspection reports with the help of
              our AI assistant. By capturing photos and voice notes on-site, our
              AI model automatically compiles accurate and well-organized
              reports. By the time you return from your site visit, your report
              is ready for review—saving you time and streamlining the reporting
              process. Focus more on the work that matters, and let Opusense AI
              handle the paperwork.
            </Text>
          </Box>
        </StackComponent>
      </Container>
      <Container
        maxW="1300px"
        // h={{ base: "1000px", md: "1200px", lg: "1200px", xl: "800px" }}
        mb={{ base: "120px", lg: "200px" }}
        id="case-studies"
        mt="130px"
      >
        <StackComponent>
          <Image
            src="case-study-collage.jpg"
            alt="case study collage"
            maxW={{ base: "350px", sm: "450px", md: "700px" }}
          />
          <VStack
            maxW={{ base: "480px", md: "700px" }}
            align={{ base: "center", xl: "flex-end" }}
          >
            <Box as="span" position="relative">
              {/* <Box
                px={1}
                position="absolute"
                width="105%"
                height="18px"
                top="49px"
                left="-2.5%"
                zIndex="-1"
                backgroundColor="oai.yellow"
              /> */}
              <Text
                as="span"
                position="relative"
                fontSize={{ base: "3xl", sm: "3xl", md: "5xl", lg: "6xl" }}
                fontWeight="bold"
                bgClip="text"
                color="black"
                fontFamily="niramit"
              >
                Proven performance in
              </Text>
            </Box>
            <Box as="span" position="relative">
              {/* <Box
                px={1}
                position="absolute"
                width="105%"
                height="18px"
                top="49px"
                left="-2.5%"
                zIndex="-1"
                backgroundColor="oai.yellow"
              /> */}
              <Text
                as="span"
                position="relative"
                zIndex="8"
                fontSize={{ base: "3xl", sm: "3xl", md: "5xl", lg: "6xl" }}
                fontWeight="bold"
                bgClip="text"
                color="black"
                fontFamily="niramit"
              >
                real-world applications.
              </Text>
            </Box>
            <Text
              fontSize={{ base: "lg", sm: "xl", md: "xl", lg: "xl" }}
              fontWeight="normal"
              fontFamily="niramit"
              color="oai.darkgrey"
              py="10px"
            >
              At Opusense AI, our sensors have proven their reliability and
              accuracy through numerous case studies conducted in the Greater
              Toronto Area and Kitchener-Waterloo. During these studies, our
              sensors successfully alerted project managers to various concrete
              irregularities, including a significant temperature spike within
              the first three days of pouring. Traditional monitoring systems
              would have detected this spike a week later, whereas our sensors
              provided immediate feedback, enabling timely corrective actions
              and ensuring the integrity of the concrete.
            </Text>
          </VStack>
        </StackComponent>
      </Container>
      <Container
        maxW="1300px"
        mb={{ base: "100px" }}
        id="maturity-method"
        mt="120px"
      >
        <StackComponent spacing="70px">
          <VStack maxW={{ base: "480px", md: "700px", xl: "500px" }}>
            <Text
              fontSize={{ base: "4xl", md: "4xl", lg: "5xl" }}
              fontWeight="bold"
              fontFamily="niramit"
              color="black"
              px="5px"
              textAlign={{ base: "center", lg: "left" }}
            >
              What is the Maturity Method?
            </Text>
            <Text
              fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "xl" }}
              fontWeight="normal"
              fontFamily="niramit"
              color="oai.darkgrey"
              py="10px"
              px="5px"
            >
              The maturity method is an established technique to estimate
              concrete strength in real-time based on temperature history. This
              method helps ensure the quality and safety of concrete structures
              by providing accurate strength predictions.
            </Text>
          </VStack>
          <VStack
            align={{ base: "center", md: "flex-start" }}
            spacing="20px"
            // maxW="1200px"
            maxW={{ base: "700px", xl: "none" }}
            px={{ base: "30px", lg: "0px" }}
            textAlign={{ base: "center", md: "start" }}
          >
            {/* <Text
              fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "3xl" }}
              fontWeight="normal"
              fontFamily="niramit"
              color="black"
            >
              Start by ...
            </Text> */}
            <StackComponent3>
              <Flex
                align="center"
                justify="center"
                minW="60px"
                h="60px"
                border="2px solid"
                borderColor="oai.darkgrey"
                borderRadius="50%"
              >
                <Text
                  fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl" }}
                  fontWeight="normal"
                  fontFamily="niramit"
                  color="oai.darkgrey"
                  py="10px"
                >
                  1
                </Text>
              </Flex>
              <Text
                fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl" }}
                fontWeight="normal"
                fontFamily="niramit"
                color="oai.darkgrey"
                mx="20px"
              >
                Calibrate sensors with each concrete mix.
              </Text>
            </StackComponent3>
            <StackComponent3>
              <Flex
                align="center"
                justify="center"
                minW="60px"
                h="60px"
                border="2px solid"
                borderColor="oai.darkgrey"
                borderRadius="50%"
              >
                <Text
                  fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl" }}
                  fontWeight="normal"
                  fontFamily="niramit"
                  color="oai.darkgrey"
                  py="10px"
                >
                  2
                </Text>
              </Flex>
              <Text
                fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl" }}
                fontWeight="normal"
                fontFamily="niramit"
                color="oai.darkgrey"
                mx="20px"
              >
                Embed sensors in concrete pours.
              </Text>
            </StackComponent3>
            <StackComponent3>
              <Flex
                align="center"
                justify="center"
                minW="60px"
                h="60px"
                border="2px solid"
                borderColor="oai.darkgrey"
                borderRadius="50%"
              >
                <Text
                  fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl" }}
                  fontWeight="normal"
                  fontFamily="niramit"
                  color="oai.darkgrey"
                  py="10px"
                >
                  3
                </Text>
              </Flex>
              <Text
                fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl" }}
                fontWeight="normal"
                fontFamily="niramit"
                color="oai.darkgrey"
                mx="20px"
              >
                Monitor and record concrete temperature over time.
              </Text>
            </StackComponent3>
            <StackComponent3>
              <Flex
                align="center"
                justify="center"
                minW="60px"
                h="60px"
                border="2px solid"
                borderColor="oai.darkgrey"
                borderRadius="50%"
              >
                <Text
                  fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl" }}
                  fontWeight="normal"
                  fontFamily="niramit"
                  color="oai.darkgrey"
                  py="10px"
                >
                  4
                </Text>
              </Flex>
              <Text
                fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl" }}
                fontWeight="normal"
                fontFamily="niramit"
                color="oai.darkgrey"
                ml="20px"
              >
                Data is then used to calculate the maturity index, which
                correlate to the concrete's strength development.
              </Text>
            </StackComponent3>
          </VStack>
        </StackComponent>
        <VStack align={{ base: "center", xl: "flex-start" }}>
          <VStack
            maxW={{ base: "480px", md: "700px", xl: "full" }}
            align={{ base: "center", sm: "flex-start" }}
            mt="150px"
            px="20px"
          >
            <Box position="relative" display="flex">
              <Box
                px={1}
                position="absolute"
                width="105%"
                height="20%"
                top="55%"
                left="-2.5%"
                zIndex="-1"
                backgroundColor="oai.yellow"
              />
              <Text
                as="span"
                position="relative"
                zIndex="8"
                fontSize={{ base: "4xl", sm: "4xl", md: "5xl", lg: "5xl" }}
                fontWeight="bold"
                bgClip="text"
                color="black"
                fontFamily="niramit"
              >
                Why is it better?
              </Text>
            </Box>
            <UnorderedList
              fontSize={{ base: "lg", sm: "xl", md: "xl", lg: "xl" }}
              fontWeight="normal"
              fontFamily="niramit"
              color="oai.darkgrey"
            >
              <ListItem>
                Immediate Data: Provides real-time strength estimates.
              </ListItem>
              <ListItem>
                Enhanced Safety: Detects potential issues early.
              </ListItem>
              <ListItem>
                Cost-Efficient: Reduces the need for traditional break tests,
                speeding up project timelines.
              </ListItem>
            </UnorderedList>
          </VStack>
        </VStack>
        <VStack
          maxW="full"
          align={{ base: "center", xl: "flex-start" }}
          mt="200px"
        >
          <Text
            as="span"
            position="relative"
            fontSize={{ base: "3xl", sm: "3xl", md: "4xl", lg: "5xl" }}
            fontWeight="bold"
            bgClip="text"
            color="black"
            fontFamily="niramit"
            px="20px"
          >
            Applications
          </Text>
          <StackComponent2 justify="space-between" align="center" w="full">
            <Card maxW="400px" h="400px">
              <CardBody>
                <Image
                  src="high-rise-scene.jpg"
                  alt="High rise scene"
                  borderRadius="lg"
                  h="300px"
                  w="390px"
                  objectFit="cover"
                />
                <Heading size="lg" h="50px" mt="20px" fontFamily="niramit">
                  High-Rises
                </Heading>
              </CardBody>
            </Card>
            <Card maxW="400px" h="400px">
              <CardBody>
                <Image
                  src="bridge-scene.jpg"
                  alt="Bridge scene"
                  borderRadius="lg"
                  h="300px"
                  w="390px"
                  objectFit="cover"
                />
                <Heading size="lg" h="50px" mt="20px" fontFamily="niramit">
                  Infrastructure
                </Heading>
              </CardBody>
            </Card>
            <Card maxW="400px" h="400px">
              <CardBody>
                <Image
                  src="precast_concrete_image.png"
                  alt="Precast concrete"
                  borderRadius="lg"
                  h="300px"
                  w="390px"
                  objectFit="cover"
                />
                <Heading size="lg" h="50px" mt="20px" fontFamily="niramit">
                  Precast
                </Heading>
              </CardBody>
            </Card>
          </StackComponent2>
        </VStack>
      </Container>
      <Container
        maxW="full"
        h={{ base: "300px" }}
        id="footer"
        backgroundColor="rgba(244, 190, 29, 0.15)"
        borderColor="oai.yellow"
        borderTopWidth="1.5px"
      >
        <Container maxW="1400px" py="20px" h="full">
          <Flex maxW="full" h="full" justify="space-between">
            <Flex
              maxW="200px"
              justify="space-between"
              direction="column"
              h="full"
            >
              <Box>
                <Image
                  src="oai-logo-long.svg"
                  alt="oai long logo"
                  maxW="200px"
                />
                <Text
                  fontSize={{ base: "l", sm: "l", md: "l", lg: "l" }}
                  fontWeight="normal"
                  fontFamily="niramit"
                  color="black"
                  mt="10px"
                >
                  AI-Driven monitoring systems for the construction industry
                </Text>
              </Box>
              <Box>
                <Text>© Opusense AI, 2024</Text>
              </Box>
            </Flex>
            <HStack alignSelf="flex-end" opacity={0.9}>
              <Link
                href="https://www.linkedin.com/company/opusense-ai/"
                isExternal
              >
                <FaLinkedin size="30px" color="oai.darkgrey" />
              </Link>
              <Link href="mailto:hello@opusense.com">
                <FaSquareEnvelope size="30px" color="oai.darkgrey" />
              </Link>
            </HStack>
          </Flex>
        </Container>
      </Container>
    </Container>
  );
};

export default Home;
