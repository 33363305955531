import { theme as origTheme, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    oai: {
      white: "#F3FFE0",
      black: "#3D3B30",
      grey: "#BED2C4",
      darkgrey: "#4D5061",
      yellow: "#F4BE1D",
      gradC1: "#F3FFE0",
      gradC2: "#BED2C4",
    },
  },
  fonts: {
    defaults: `-apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol"`,
    niramit: `'Niramit', sans-serif`,
    // specialfont: `'Segoe UI', -apple-system, BlinkMacSystemFont, 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  },
  components: {
    Alert: {
      variants: {
        subtle: (props) => {
          const { colorScheme: c } = props;
          if (c !== "arbor.white") {
            // use original definition for all color schemes
            return origTheme.components.Alert.variants.subtle(props);
          }
          return {
            container: {
              background: "white",
              color: "gray.700",
            },
          };
        },
      },
    },
  },
});

export default theme;
