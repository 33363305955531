import React, { useState } from "react"
import Register from "../register/register" // Importing the Register component
import { Auth } from "aws-amplify"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../AuthContext"

import {
  useToast,
  Text,
  Divider,
  Spacer,
  Flex,
  Center,
  Box,
  HStack,
  VStack,
  Link as LinkComp,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Container,
  Image,
  List,
  ListItem,
  ListIcon,
  UnorderedList,
} from "@chakra-ui/react"

import { ArrowBackIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons"

import { MdCheckCircle, MdCancel } from "react-icons/md"

const NewPW = ({ cogUser, setNewPWFlag }) => {
  // State for Toast Dialog
  const toast = useToast()

  // State for hiding and showing password
  const [hidden, setHidden] = useState(true)

  const [activePW, setActivePW] = useState(false)
  const [newPW, setNewPW] = useState("")
  const [pwCriteria, setPWCriteria] = useState({
    minLength: false,
    oneUpperCase: false,
    oneLowerCase: false,
    oneNumber: false,
  })

  const handleNewPWChange = (event) => {
    const { value } = event.target
    setActivePW(true)
    setNewPW(value)
    setPWCriteria({
      minLength: value.length >= 8,
      oneUpperCase: /[A-Z]/.test(value),
      oneLowerCase: /[a-z]/.test(value),
      oneNumber: /[0-9]/.test(value),
    })
  }

  const updatePW = async (event) => {
    event.preventDefault()
    try {
      await Auth.completeNewPassword(cogUser, newPW)
      toast({
        title: "Password updated successfully.",
        description: "Please login with your new password.",
        position: "bottom-right",
        variant: "subtle",
        status: "success",
        isClosable: true,
        duration: 5000,
        containerStyle: {
          fontSize: "14px",
        },
      })
      setNewPWFlag(false)
    } catch (error) {
      console.error("Error completing new password challenge:", error)
    }
  }

  return (
    <form onSubmit={updatePW}>
      <VStack spacing={8} align="stretch">
        {!activePW ? (
          <UnorderedList spacing={3}>
            <ListItem>At least 8 characters</ListItem>
            <ListItem>At least 1 Uppercase character</ListItem>
            <ListItem>At least 1 Lowercase character</ListItem>
            <ListItem>At least 1 Number</ListItem>
          </UnorderedList>
        ) : (
          <List spacing={3}>
            <ListItem color={pwCriteria.minLength ? "green.500" : "black.500"}>
              <ListIcon
                as={pwCriteria.minLength ? MdCheckCircle : MdCancel}
                color={pwCriteria.minLength ? "green.500" : "black.500"}
              />
              At least 8 characters
            </ListItem>
            <ListItem
              color={pwCriteria.oneUpperCase ? "green.500" : "black.500"}
            >
              <ListIcon
                as={pwCriteria.oneUpperCase ? MdCheckCircle : MdCancel}
                color={pwCriteria.oneUpperCase ? "green.500" : "black.500"}
              />
              At least 1 Uppercase character
            </ListItem>
            <ListItem
              color={pwCriteria.oneLowerCase ? "green.500" : "black.500"}
            >
              <ListIcon
                as={pwCriteria.oneLowerCase ? MdCheckCircle : MdCancel}
                color={pwCriteria.oneLowerCase ? "green.500" : "black.500"}
              />
              At least 1 Lowercase character
            </ListItem>
            <ListItem color={pwCriteria.oneNumber ? "green.500" : "black.500"}>
              <ListIcon
                as={pwCriteria.oneNumber ? MdCheckCircle : MdCancel}
                color={pwCriteria.oneNumber ? "green.500" : "black.500"}
              />
              At least 1 Number
            </ListItem>
          </List>
        )}
        <Box>
          <FormLabel as="new-pw-label">
            <Text as="b" fontSize="xs" color="gray.600">
              New Password
            </Text>
          </FormLabel>
          <InputGroup>
            <Input
              id="new-pw-input"
              name="new-password"
              type={hidden ? "password" : "text"}
              autoComplete="new-password"
              role="switch"
              onChange={handleNewPWChange}
              pt={6}
              pr={4}
              pb={6}
              pl={4}
              size="sm"
              boxShadow="sm"
              bg="white"
              rounded="md"
              placeholder="Enter your new password"
            />
            <InputRightElement p="6">
              <Button
                onClick={() => setHidden(!hidden)}
                color="gray.300"
                _hover={{ color: "gray.500" }}
                variant="link"
              >
                {hidden ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
        <Box display="flex" justifyContent="center" w="full">
          <Button
            type="submit"
            mr={2}
            variant="solid"
            colorScheme="yellow"
            color="white"
            w="50%"
          >
            Update Password
          </Button>
        </Box>
      </VStack>
    </form>
  )
}

export default NewPW //exporting the login component
