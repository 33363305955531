/*
 * GraphDisplay.js
 * coop@arbor-iot.com
 * 2024-04-09
 * Component to render leaf data
 */
import React from "react"
import { Line } from "react-chartjs-2"
import "chart.js/auto"
import { Chart, registerables } from "chart.js"
import zoomPlugin from "chartjs-plugin-zoom"
import "chartjs-adapter-date-fns"
import annotationPlugin from "chartjs-plugin-annotation"

// Register plugins
Chart.register(...registerables, zoomPlugin, annotationPlugin)

const GraphDisplay = ({ leafData, graphType, pourData }) => {
  const startDate = new Date(pourData.startDate)
  const endDate = new Date(pourData.endDate)
  // console.log("Pour Data", pourData)

  // leafData = leafData.filter((i) => {
  //   return new Date(i.updatedAt).getTime() > startDate.getTime()
  // })
  // console.log("Filtered", leafData)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true, // Enable zooming using mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming using pinch gesture
          },
          mode: "x", // Enable zooming in the x direction
        },
        pan: {
          enabled: true, // Enable panning
          mode: "x", // Enable panning in the x direction
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          // Custom formatting based on the zoom level
          displayFormats: {
            millisecond: "HH:mm:ss.SSS",
            second: "HH:mm:ss",
            minute: "HH:mm",
            hour: "HH:mm",
            day: "MMM dd",
            week: "MMM dd",
            month: "MMM yyyy",
            quarter: "[Q]Q - yyyy",
            year: "yyyy",
          },
        },
        ticks: {
          // Adjust as needed
          maxRotation: 0,
          autoSkip: true,
          autoSkipPadding: 15,
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        title: {
          display: true,
          text: "Temperature (°C)",
        },
      },
    },
  }

  const labels = leafData.map((item) => item.createdAt)
  switch (graphType) {
    case "conTemp":
      var temps = leafData.map((item) => item.temp)
      break
    case "ambTemp":
      var temps = leafData.map((item) => item.temp2)
      break
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Temperature",
        data: temps,
        fill: false,
        borderColor: "rgb(0, 0, 0)",
        tension: 0.1,
      },
    ],
  }

  return <Line data={data} options={options} />
}

export default GraphDisplay
