/*
 * PourInfoService.js
 * coop@arbor-iot.com
 * 2024-04-09
 * Wrapper around API endpoints, providing basic CRUD operations for managing data within the PourInfoTable
 */
import axios from "axios";

const getPoursByOwnerId = async (ownerID) => {
  try {
    const { data: response } = await axios.get("/pour/filter", {
      params: { ownerID },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const updatePour = async (pourProps) => {
  try {
    const { data: response } = await axios.put("/pour/", pourProps);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const createPour = async (newPour) => {
  try {
    const { data: response } = await axios.post("/pour/", newPour);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const filterPours = async (filters) => {
  try {
    const { data: response } = await axios.get("/pour/filter", {
      params: filters,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export { getPoursByOwnerId, updatePour, createPour, filterPours };
