/**
 * deviceRegistration.js
 * Author: Michael Bacani
 * Last Updated: 2024-07-09
 * Front-end for device registration form.
 */

// React imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Chakra-UI components
import {
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  Tooltip,
  HStack,
  Button,
} from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";

// Custom components

// Data Services
import { getUserSub } from "../../services/UserInfoService";
import {
  getDeviceBySN,
  updateDeviceOwner,
} from "../../services/LeafInfoService";
import { MainNav } from "../components/MainNav";

const DeviceRegistration = () => {
  const [leafSN, setLeafSN] = useState("");
  const [productKey, setProductKey] = useState("");
  const [aliasInput, setAliasInput] = useState("");
  const [registeredDev, setRegisteredDev] = useState("");
  const [ownerID, setOwnerID] = useState("");

  const [formError, setFormError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const getCognitoSub = async () => {
      const sub = await getUserSub();
      setOwnerID(sub);
    };
    getCognitoSub();
  }, []);

  useEffect(() => {
    console.log(registeredDev);
    if (registeredDev === undefined) {
      console.log("Incorrect SN and PK");
    } else {
      console.log("Found device");
      console.log(registeredDev.ownerID);
      if (registeredDev.ownerID === null) {
        console.log("Unregistered device");
        console.log(registeredDev.devEUI);
        updateDeviceOwner(registeredDev.devEUI, ownerID);
        navigate("/device_registration_success");
      } else {
        console.log("Registered device");
      }
    }
  }, [registeredDev]);

  const onClearPress = () => {
    setLeafSN("");
    setProductKey("");
    setAliasInput("");
  };

  const onButtonPress = async () => {
    if (!leafSN || !productKey) {
      console.log("Need to input SN and PID");
      // Add code if form wasn't filled
      setFormError("Required Input");
      return;
    }

    const intLeafSN = Number(leafSN);
    getDeviceBySN(intLeafSN, productKey, setRegisteredDev);
  };

  const handleLeafSNChange = (e) => {
    setLeafSN(e.target.value);
  };

  const handleProductKeyChange = (e) => {
    setProductKey(e.target.value);
  };

  const handleAliasInput = (e) => {
    setAliasInput(e.target.value);
  };

  return (
    <Container minW="100vw" h="100vh" margin="0" padding="0">
      <VStack w="full" h="full">
        <MainNav />
        <VStack
          w={{ base: "90%", sm: "450px" }}
          alignItems="start"
          flex={1}
          justify="center"
          mb="200px"
        >
          <Heading>Register a New Sensor</Heading>
          <Text>Register your sensor to begin monitoring your data.</Text>
          <Divider size="1px" borderColor="gray.200" mt="10px" mb="10px" />
          <FormControl>
            <FormLabel>
              Sensor SN*
              <Tooltip label="Find the Sensor SN at the bottom of the Sensor">
                <QuestionIcon boxSize={3.5} color="gray.700" m="4px" />
              </Tooltip>
            </FormLabel>
            <Input value={leafSN} onChange={handleLeafSNChange} />
            <FormLabel mt="6px">
              Product Key*
              <Tooltip label="Find the Product ID at the bottom of the Sensor">
                <QuestionIcon boxSize={3.5} color="gray.700" m="4px" />
              </Tooltip>
            </FormLabel>
            <Input value={productKey} onChange={handleProductKeyChange} />
            <FormLabel mt="6px">
              Alias (Recommended)
              <Tooltip label="The Sensor Alias will be used to describe the sensor in the app rather than the Sensor SN">
                <QuestionIcon boxSize={3.5} color="gray.700" m="4px" />
              </Tooltip>
            </FormLabel>
            <Input value={aliasInput} onChange={handleAliasInput} />
          </FormControl>
          <HStack w="full" justify="center" spacing={4} mt="20px">
            <Button w="300px" onClick={onClearPress}>
              Clear
            </Button>
            <Button w="300px" onClick={onButtonPress}>
              Register
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </Container>
  );
};

export default DeviceRegistration;
