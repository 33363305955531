/**
 * deviceRegistrationSuccess.js
 * Author: Michael Bacani
 * Last Updated: 2024-07-09
 * Displayed when device registration was successful.
 */

import { useNavigate } from "react-router-dom";

import {
  Container,
  Heading,
  VStack,
  Button,
  ButtonGroup,
  Circle,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { MainNav } from "../components/MainNav";

const DeviceRegistrationSuccess = () => {
  const navigate = useNavigate();

  const onAddPress = () => {
    navigate("/device_registration");
  };

  const onMonitorPress = () => {
    navigate("/main");
  };

  return (
    <Container minW="100vw" h="100vh" margin="0" padding="0" overflowX="hidden">
      <VStack w="full" h="full">
        <MainNav />
        <VStack
          w={{ base: "90%", sm: "600px" }}
          align="center"
          flex={1}
          justify="center"
          mb="200px"
        >
          <Circle
            size="200px"
            bg="transparent"
            border="8px"
            borderColor="#1a202c"
            mb="15px"
          >
            <CheckIcon boxSize={20} />
          </Circle>
          <Heading
            mb="15px"
            fontSize={{ base: "xl", sm: "3xl" }}
            textAlign="center"
          >
            Device Registration Successful
          </Heading>
          <ButtonGroup size="md" align="center">
            <Button onClick={onAddPress}>+ Add Another Sensor</Button>
            <Button onClick={onMonitorPress}>Monitor</Button>
          </ButtonGroup>
        </VStack>
      </VStack>
    </Container>
  );
};

export default DeviceRegistrationSuccess;
