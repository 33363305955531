/**
 * KPIDisplay.js
 * Author: Michael Bacani
 * Last Updated: 2024-07-09
 * Displays leaf data from its last write.
 */

import { Stat, StatLabel, StatNumber, Center, Stack } from "@chakra-ui/react";
import { formatDate } from "../../../utils/OpusenseHelpers";

const KPIDisplay = ({ leafData }) => {
  return (
    <Stack
      h={{ base: "100px", lg: "full" }}
      w={{ base: "full", lg: "250px" }}
      borderRight="1px"
      borderStyle="solid"
      borderColor="gray.200"
      spacing={{ base: 1, lg: 0 }}
      direction={{ base: "row", lg: "column" }}
    >
      <Center
        w="full"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="gray.200"
        align="start"
        py={3}
        px={4}
      >
        <Stat>
          <StatLabel mb={0} fontSize="xs" fontWeight="bold">
            Last Updated
          </StatLabel>
          <StatLabel fontSize="xs">
            {leafData.length
              ? formatDate(leafData[leafData.length - 1].updatedAt)
              : "-"}
          </StatLabel>
        </Stat>
      </Center>
      <Center
        w="full"
        h="full"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="gray.200"
        align="start"
        py={2}
        px={4}
      >
        <Stat>
          <StatLabel>Concrete Temperature</StatLabel>
          <StatNumber>
            {leafData.length
              ? Math.round(leafData[leafData.length - 1].temp * 10) / 10 + "ºC"
              : "-"}
          </StatNumber>
        </Stat>
      </Center>
      <Center
        w="full"
        h="full"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="gray.200"
        align="start"
        py={2}
        px={4}
      >
        <Stat>
          <StatLabel>Ambient Temperature</StatLabel>
          <StatNumber>
            {leafData.length
              ? Math.round(leafData[leafData.length - 1].temp2 * 10) / 10 + "ºC"
              : "-"}
          </StatNumber>
        </Stat>
      </Center>
      <Center
        w="full"
        h="full"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="gray.200"
        align="start"
        py={2}
        px={4}
      >
        <Stat>
          <StatLabel>Strength</StatLabel>
          <StatNumber>{leafData.length ? "-" : "-"}</StatNumber>
        </Stat>
      </Center>
    </Stack>
  );
};

export default KPIDisplay;
