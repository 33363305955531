/**
 * MainNav.js
 * Author: Michael Bacani
 * Last Updated: 2024-07-09
 * Apps navigation bar
 */

import { Link } from "react-router-dom";
import {
  Text,
  Center,
  Icon,
  Spacer,
  Box,
  Image,
  Container,
  Flex,
  Button,
  useDisclosure,
  Stack,
  VStack,
} from "@chakra-ui/react";

// React imports
import React from "react";
import { useLocation } from "react-router-dom";

import { FaTv, FaUser } from "react-icons/fa6";
import { MdSensors } from "react-icons/md";

import { useAuth } from "../../AuthContext";

import { signOut } from "../../utils/OpusenseHelpers";
import { HashLink } from "react-router-hash-link";

const MainNav = () => {
  const { setIsAuthenticated } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box
      position="fixed"
      top="0"
      as="header"
      w="full"
      bg="gray.800"
      px={0}
      zIndex={2}
      h="48px"
    >
      <Flex align="stretch" h="full">
        <Link to="/">
          <Center h="full" ml="10px" overflow="hidden">
            <Image
              src="/oai-logo-long-white.svg"
              boxSize="150px"
              alt="Opusense Logo"
            />
          </Center>
        </Link>
        <Spacer />
        <Flex display={{ base: "none", md: "flex" }}>
          <Link to="/main">
            <NavButton isActive={currentPath === "/main"} iconLabel={FaTv}>
              Pours
            </NavButton>
          </Link>
          <Link to="/device_manager">
            <NavButton
              isActive={
                currentPath === "/device_registration" ||
                currentPath === "/device_manager"
              }
              iconLabel={MdSensors}
            >
              Devices
            </NavButton>
          </Link>
          <Link to="/account">
            <NavButton isActive={currentPath === "/account"} iconLabel={FaUser}>
              Account
            </NavButton>
          </Link>
        </Flex>
        <Spacer />
        <Flex display={{ base: "none", md: "flex" }}>
          <Box mx={2}>
            <Center h="full">
              <Button
                h={10}
                fontWeight="bold"
                variant="outline"
                fontSize={{ base: "xs", sm: "sm" }}
                p={{ base: 2, sm: 3 }}
                my={3}
                borderWidth="2px"
                borderColor={"white"}
                color={"white"}
                _hover={{
                  background: "none",
                }}
                onClick={() => signOut(setIsAuthenticated)}
              >
                <Text fontSize="xs">Sign Out</Text>
              </Button>
            </Center>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

const MobileMainNav = () => {
  const { setIsAuthenticated } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box
      as="footer"
      position="fixed"
      bottom="0"
      width="100%"
      bg="gray.800"
      color="white"
      boxShadow="md"
      display={{ base: "flex", md: "none" }}
      justifyContent="space-around"
      h="40px"
    >
      <Link to="/main">
        <MobileNavButton isActive={currentPath === "/main"} iconLabel={FaTv}>
          Pours
        </MobileNavButton>
      </Link>
      <Link to="/device_manager">
        <MobileNavButton
          isActive={
            currentPath === "/device_registration" ||
            currentPath === "/device_manager"
          }
          iconLabel={MdSensors}
        >
          Devices
        </MobileNavButton>
      </Link>
      <Link to="/account">
        <MobileNavButton
          isActive={currentPath === "/account"}
          iconLabel={FaUser}
        >
          Account
        </MobileNavButton>
      </Link>
    </Box>
  );
};

const NavButton = ({ isActive, iconLabel, children }) => {
  return (
    <>
      {isActive ? (
        <Button
          w="full"
          h="full"
          mx={3}
          p={2}
          color="white"
          variant="ghost"
          borderRadius="0px"
          borderTop="4px solid transparent"
          borderBottom="4px solid #ECC94B"
          _hover={{
            bg: "gray.600",
          }}
        >
          <Icon mr={1} boxSize={4} as={iconLabel} />
          <Text fontSize="sm" fontWeight="normal">
            {children}
          </Text>
        </Button>
      ) : (
        <Button
          w="full"
          h="full"
          mx={3}
          p={2}
          color="white"
          variant="ghost"
          borderRadius="0px"
          borderTop="4px solid transparent"
          borderBottom="4px solid transparent"
          _hover={{
            bg: "gray.600",
            borderBottom: "4px solid #CBD5E0",
          }}
        >
          <Icon mr={1} boxSize={4} as={iconLabel} />
          <Text fontSize="sm" fontWeight="normal">
            {children}
          </Text>
        </Button>
      )}
    </>
  );
};

const MobileNavButton = ({ isActive, iconLabel, children }) => {
  return (
    <>
      {isActive ? (
        <Button
          h="full"
          mx={3}
          p={2}
          color="white"
          variant="ghost"
          borderRadius="0px"
          borderTop="4px solid #ECC94B"
          _hover={{
            bg: "gray.600",
          }}
        >
          <VStack spacing={0}>
            <Icon boxSize={3} as={iconLabel} />
            <Text fontSize="sm" fontWeight="normal">
              {children}
            </Text>
          </VStack>
        </Button>
      ) : (
        <Button
          h="full"
          mx={3}
          p={2}
          color="white"
          variant="ghost"
          borderRadius="0px"
          borderTop="4px solid transparent"
          _hover={{
            bg: "gray.600",
            borderTop: "4px solid #CBD5E0",
          }}
        >
          <VStack spacing={0}>
            <Icon boxSize={3} as={iconLabel} />
            <Text fontSize="sm" fontWeight="normal">
              {children}
            </Text>
          </VStack>
        </Button>
      )}
    </>
  );
};

export { MobileMainNav, MainNav };
