/*
 * NavBar.js
 * coop@arbor-iot.com
 * 2024-04-09
 * Header for Arbor landing page
 */
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { useAuth } from "../../../AuthContext.js";
import {
  Box,
  Image,
  Container,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

export default function NavBar(props) {
  const { isOpen, onToggle } = useDisclosure();
  const { isAuthenticated } = useAuth();
  return (
    <Box
      as="header"
      w="100%"
      position="fixed"
      top="0"
      zIndex="10"
      backdropFilter="auto"
      backdropBlur="12px"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      {...props}
    >
      <Container maxW="full">
        <Flex h={"60px"} align={"center"} maxW={"full"}>
          {/* NOTE: DesktopNav */}
          <Flex flex={{ base: 1 }} justify="flex-start">
            <HashLink smooth to="#">
              <Image src="oai-logo-long.svg" height="30px" alt="OAI" />
            </HashLink>
            <Flex display={{ base: "none", lg: "flex" }} ml={12}>
              <DesktopNav />
            </Flex>
          </Flex>

          {/* NOTE: Login Btn */}
          <Stack
            flex={{ base: 1, lg: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={3}
            mx={3}
          >
            {isAuthenticated ? (
              <Link to="/main">
                <Button
                  h={10}
                  fontWeight="bold"
                  variant="outline"
                  fontSize={{ base: "xs", sm: "sm" }}
                  p={{ base: 2, sm: 3 }}
                  my={3}
                  borderWidth="1px"
                  borderColor={"black"}
                  color={"black"}
                >
                  Sign in
                </Button>
              </Link>
            ) : (
              <Link to="/login">
                <Button
                  h={10}
                  fontWeight="bold"
                  variant="outline"
                  fontSize={{ base: "xs", sm: "sm" }}
                  p={{ base: 2, sm: 3 }}
                  my={3}
                  borderWidth="1px"
                  borderColor={"black"}
                  color={"black"}
                >
                  Sign in
                </Button>
              </Link>
            )}
          </Stack>

          {/* NOTE: MobileNav Btn */}
          <Flex
            flex={{ base: 0, lg: "auto" }}
            display={{ base: "flex", lg: "none" }}
            justifyContent="flex-end"
          >
            <IconButton
              onClick={onToggle}
              color={"arbor.white"}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              _hover={{ background: "arbor.gradC2" }}
              _active={{
                background: "arbor.gradC2",
              }}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
        </Flex>
        {/*NOTE: Mobile nav items*/}
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Container>
    </Box>
  );
}

const DesktopNav = () => {
  return (
    <Stack direction={"row"} spacing={8} align="center">
      {navbar_items.map((navItem) => (
        <HashLink smooth to={navItem.link} key={navItem.label}>
          <Button
            fontSize={"md"}
            color={"black"}
            // NOTE: could change to yellow
            _hover={{ color: "arbor.grey" }}
            variant="link"
          >
            {navItem.label}
          </Button>
        </HashLink>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack p={3} display={{ lg: "none" }} pb={8} maxW="full">
      {navbar_items.map((navItem) => (
        <MobileItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileItem = (NavItem) => {
  const { onToggle } = useDisclosure();
  const { label, link } = NavItem;
  return (
    <Stack onClick={onToggle}>
      <HashLink smooth to={link}>
        <Box
          p={3}
          justifyContent="space-between"
          alignItems="center"
          fontSize={"md"}
          fontWeight="600"
          variant="ghost"
          color={"arbor.darkgrey"}
          borderRadius="none"
          // NOTE: could change to yellow
          _hover={{
            color: "arbor.grey",
          }}
        >
          <Text>{label}</Text>
        </Box>
      </HashLink>
    </Stack>
  );
};

// NOTE: Format is Array<NavItem>
const navbar_items = [
  {
    label: "Products and Services",
    link: "/#products",
  },
  {
    label: "Case Studies",
    link: "/#case-studies",
  },

  {
    label: "Maturity Method",
    link: "/#maturity-method",
  },
];
