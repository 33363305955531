import {
  Box,
  Button,
  Container,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MainNav, MobileMainNav } from "../components/MainNav";
import { useEffect, useState } from "react";
import {
  getUserCredentials,
  getUserInfo,
  updateUserInfo,
} from "../../services/UserInfoService";

import { useForm } from "react-hook-form";

const AccountManager = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const updatedUser = {
      ...user,
      thresholdLB: tempControlWatch === "disable" ? null : Number(data.minTemp),
      thresholdUB: tempControlWatch === "disable" ? null : Number(data.maxTemp),
      maxRate: maxRateControlWatch === "disable" ? null : Number(data.maxRate),
      maxTempDifference:
        maxDiffControlWatch === "disable" ? null : Number(data.maxDiff),
    };

    await updateUserInfo(updatedUser);
    setUser(updatedUser); // Update the state to reflect changes in the UI
  };

  const [user, setUser] = useState({});
  const [sub, setUserSub] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [username, setUsername] = useState("");

  // Required to defer RadioGroup 'defaultValue' until data is fetched from database
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCognitoInfo = async () => {
      const { attributes, username } = await getUserCredentials();
      setUserSub(attributes.sub);
      setUserEmail(attributes.email);
      setUsername(username);
    };
    getCognitoInfo();
  });

  useEffect(() => {
    const getTableInfo = async () => {
      if (!sub) return;
      const userInfo = await getUserInfo(sub);
      setUser(userInfo);

      // Set form values based on the fetched data
      setValue("minTemp", userInfo.thresholdLB);
      setValue("maxTemp", userInfo.thresholdUB);
      setValue("maxRate", userInfo.maxRate);
      setValue("maxDiff", userInfo.maxTempDifference);

      // Set the radio buttons based on whether userInfo.value is undefined
      const tempControlValue =
        (userInfo.thresholdLB === null ? "disable" : "enable") ||
        (userInfo.thresholdUB === null ? "disable" : "enable");
      const maxRateControlValue =
        userInfo.maxRate === null ? "disable" : "enable";
      const maxDiffControlValue =
        userInfo.maxTempDifference === null ? "disable" : "enable";

      setTempControl(tempControlValue);
      setMaxRateControl(maxRateControlValue);
      setMaxDiffControl(maxDiffControlValue);

      setValue("tempControl", tempControlValue);
      setValue("maxRateControl", maxRateControlValue);
      setValue("maxDiffControl", maxDiffControlValue);

      setLoading(false);
    };
    getTableInfo();
  }, [sub, setValue]);

  // State to hold radio button default values
  const [tempControl, setTempControl] = useState("enable");
  const [maxRateControl, setMaxRateControl] = useState("enable");
  const [maxDiffControl, setMaxDiffControl] = useState("enable");

  // Watch the radio button values
  const tempControlWatch = watch("tempControl");
  const maxRateControlWatch = watch("maxRateControl");
  const maxDiffControlWatch = watch("maxDiffControl");

  // Watch minTemp and maxTemp for absolute temperature validation (minTemp < maxTemp)
  const minTemp = watch("minTemp");
  const maxTemp = watch("maxTemp");

  if (loading) {
    return null; // Show a loading state while data is being fetched
  }

  return (
    <Container
      maxW="full"
      h="100vh"
      margin="0"
      padding="0"
      overflowX="hidden"
      overflowY="hidden"
    >
      <MainNav />
      <VStack
        w="100vw"
        h="calc(100vh - 48px)"
        // margin="0"
        padding={0}
        mt="48px"
        pb="50px"
        overflowX="hidden"
        overflowY="auto"
      >
        <Box
          w="80%"
          maxW="400px"
          mt="20px"
          borderWidth="1px"
          borderColor="grey.200"
          borderRadius="md"
          p={4}
        >
          <Text fontWeight="bold" fontSize="xl" mb="10px">
            Account Settings
          </Text>
          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input mb="8px" placeholder={username} disabled={true} />
            {/* <FormLabel>Password</FormLabel>
            <Input mb="8px" placeholder="password" /> */}
            <FormLabel>Email</FormLabel>
            <Input mb="8px" placeholder={userEmail} disabled={true} />
          </FormControl>
        </Box>

        <Box
          w="80%"
          maxW="400px"
          mt="20px"
          borderWidth="1px"
          borderColor="grey.200"
          borderRadius="md"
          p={4}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Text fontWeight="bold" fontSize="xl" mb="10px">
              Notifications
            </Text>
            <FormLabel htmlFor="minTemp">Absolute temperature (°C)</FormLabel>

            {/* Temperature Form Control */}
            <RadioGroup defaultValue={tempControl} mb="6px">
              <HStack spacing="24px">
                <Radio value="enable" {...register("tempControl")}>
                  Enable
                </Radio>
                <Radio value="disable" {...register("tempControl")}>
                  Disable
                </Radio>
              </HStack>
            </RadioGroup>

            <HStack align="start">
              {/* Min. Temperature Form Control */}
              <Box flex={1}>
                <FormLabel htmlFor="minTemp">Min. temperature (°C)</FormLabel>
                <FormControl
                  isInvalid={errors.minTemp}
                  isDisabled={tempControlWatch === "disable"}
                >
                  <Input
                    id="minTemp"
                    type="number"
                    placeholder="Min. temperature"
                    {...register("minTemp", {
                      required:
                        tempControlWatch !== "disable"
                          ? "This is required"
                          : false,
                      max: 999,
                      min: 0,
                      validate: (value) =>
                        tempControlWatch === "disable" ||
                        minTemp < maxTemp ||
                        "Min. temperature must be less than max. temperature",
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.minTemp && errors.minTemp.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              {/* Max. Temperature Form Control */}
              <Box flex={1}>
                <FormLabel htmlFor="minTemp">Max. temperature (°C)</FormLabel>
                <FormControl
                  isInvalid={errors.maxTemp}
                  isDisabled={tempControlWatch === "disable"}
                >
                  <Input
                    id="maxTemp"
                    type="number"
                    placeholder="Max. temperature"
                    {...register("maxTemp", {
                      required:
                        tempControlWatch !== "disable"
                          ? "This is required"
                          : false,
                      max: 999,
                      min: 0,
                    })}
                  />
                  <FormErrorMessage>
                    {errors.maxTemp && errors.maxTemp.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </HStack>

            {/* Max. Rate Form Control */}
            <FormLabel mt={4} htmlFor="maxRate">
              Rate of change (°C per hour)
            </FormLabel>
            <RadioGroup defaultValue={maxRateControl} mb="6px">
              <HStack spacing="24px">
                <Radio value="enable" {...register("maxRateControl")}>
                  Enable
                </Radio>
                <Radio value="disable" {...register("maxRateControl")}>
                  Disable
                </Radio>
              </HStack>
            </RadioGroup>
            <FormControl
              isInvalid={errors.maxRate}
              isDisabled={maxRateControlWatch === "disable"}
            >
              <Input
                id="maxRate"
                type="number"
                placeholder="Rate of change"
                {...register("maxRate", {
                  required:
                    maxRateControlWatch !== "disable"
                      ? "This is required"
                      : false,
                  max: 999,
                  min: 0,
                })}
              />
              <FormErrorMessage>
                {errors.maxRate && errors.maxRate.message}
              </FormErrorMessage>
            </FormControl>

            {/* Max. Difference Form Control */}
            <FormLabel mt={4} htmlFor="maxDiff">
              Temperature difference (°C)
            </FormLabel>
            <RadioGroup defaultValue={maxDiffControl} mb="6px">
              <HStack spacing="24px">
                <Radio value="enable" {...register("maxDiffControl")}>
                  Enable
                </Radio>
                <Radio value="disable" {...register("maxDiffControl")}>
                  Disable
                </Radio>
              </HStack>
            </RadioGroup>
            <FormControl
              isInvalid={errors.maxDiff}
              isDisabled={maxDiffControlWatch === "disable"}
            >
              <Input
                id="maxDiff"
                type="number"
                placeholder="Max. difference"
                {...register("maxDiff", {
                  required:
                    maxDiffControlWatch !== "disable"
                      ? "This is required"
                      : false,
                  max: 999,
                  min: 0,
                })}
              />
              <FormErrorMessage>
                {errors.maxDiff && errors.maxDiff.message}
              </FormErrorMessage>
            </FormControl>

            <Button mt={4} isLoading={isSubmitting} type="submit">
              Submit
            </Button>
          </form>
        </Box>
        <MobileMainNav />
      </VStack>
    </Container>
  );
};

export default AccountManager;
