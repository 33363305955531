import { Auth } from "aws-amplify"
import { DynamoDB } from "aws-sdk"

async function getLatestByPartitionKey(devEUI) {
  const credentials = await Auth.currentCredentials()
  const docClient = new DynamoDB.DocumentClient({
    region: "us-east-1",
    credentials: Auth.essentialCredentials(credentials),
  })

  const params = {
    TableName: "SensorData",
    KeyConditionExpression: "deviceID = :deviceID",
    ExpressionAttributeValues: {
      ":deviceID": devEUI,
    },
    ScanIndexForward: false,
    Limit: 1,
  }
  try {
    const data = await docClient.query(params).promise()
    return data.Items[0]
  } catch (err) {
    console.error(err)
  }
}

export async function getLatestReadings(devEUIs, setData) {
  let resultsObj = {}; // Initialize an empty object

  const fetchPromises = devEUIs.map(devEUI =>
    getLatestByPartitionKey(devEUI)
      .then(result => {
        resultsObj[devEUI] = result; // Store the result using the partition key as the object key
      })
  );

  try {
    await Promise.all(fetchPromises);
    setData(resultsObj)
    return resultsObj; // Return the object containing all results
  } catch (error) {
    console.error("Error fetching latest for each partition key:", error);
    throw error; // Handle error appropriately
  }
}
