import React, { useState, useEffect, useRef } from "react"
import { Container, VStack, HStack, Image, Box } from "@chakra-ui/react"
import { chakra, shouldForwardProp } from "@chakra-ui/react"
import { motion, isValidMotionProp } from "framer-motion"
import Marquee from "react-fast-marquee"
const ChakraFramerImg = chakra(motion.img, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
})
const ChakraFramerDiv = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
})

const images = [
  {
    src: "/partner-logos/co-kitchener.png",
    alt: "City of Kitchener",
    h: { base: 16, sm: 20, md: 24 },
  },
  {
    src: "/partner-logos/ms-startups.png",
    alt: "Microsoft Startups",
    h: { base: 12, sm: 16, md: 20 },
  },
  {
    src: "/partner-logos/velocity.png",
    alt: "Velocity",
    h: { base: 8, sm: 10, md: 12 },
  },

  {
    src: "/partner-logos/communitech.png",
    alt: "Communitech",
    h: { base: 6, sm: 8, md: 12 },
  },
  {
    src: "/partner-logos/conrad.png",
    alt: "Conrad",
    h: { base: 16, md: 20 },
  },
  {
    src: "/partner-logos/starter.png",
    alt: "Starter Company Plus",
    h: { base: 16, md: 20 },
  },
]

const LogoCloud = (prop) => {
  return (
    <Container maxW="6xl">
      <DesktopCloud />
    </Container>
  )
}

const DesktopCloud = (props) => {
  return (
    <HStack
      maxW="full"
      textAlign="center"
      justifyContent="space-between"
      spacing={"8"}
      {...props}
    >
      <Marquee>
        {images.map((image, index) => (
          <ChakraFramerDiv
            m={{ base: 3, md: 5 }}
            key={index}
            as={motion.div}
            transition={{
              duration: 3,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "loop",
            }}
          >
            <ChakraFramerImg
              // filter="brightness(0) invert(1) contrast(200%)"
              filter="grayscale(100%) contrast(20%)"
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.3 },
                filter: "grayscale(0.3)",
              }}
              h={image.h}
              src={image.src}
              alt={image.alt}
            />
          </ChakraFramerDiv>
        ))}
      </Marquee>
    </HStack>
  )
}

export default LogoCloud
