/**
 * deviceManager.js
 * Author: Michael Bacani
 * Last Updated: 2024-07-09
 * Displays all devices associated to the user with the LeafButton component
 * Leads into device registration by clicking the "+Add New Sensor" button
 */

// React imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// UI components
import {
  Input,
  Button,
  Container,
  HStack,
  VStack,
  Divider,
  Text,
  Heading,
  Table,
  TableContainer,
  Thead,
  Th,
  Tr,
  Box,
  Td,
  Tbody,
  Stack,
  FormControl,
  FormLabel,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
} from "@fortawesome/free-solid-svg-icons";

// Company components
import LeafButton from "./components/LeafButton";

// Data Services
import { getLeavesByOwnerID } from "../../services/LeafInfoService";
import {
  getUserInfo,
  getUserSub,
  updateUserInfo,
} from "../../services/UserInfoService";
import { getLatestReadings } from "../../services/maturityDataService";
import { getPoursByOwnerId } from "../../services/PourInfoService";

// Helpers
import { formatDate, sortLeaves } from "../../utils/OpusenseHelpers";
import { CloseIcon } from "@chakra-ui/icons";
import { MainNav, MobileMainNav } from "../components/MainNav";

const DeviceManager = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLeaf, setSelectedLeaf] = useState("");
  const [leavesReadings, setLeavesReadings] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [ownerID, setOwnerID] = useState("");
  const [pours, setPours] = useState([]);
  const [tabName, setTab] = useState("Devices"); // Deprecated
  const [upperBound, setUpperBound] = useState("");
  const [lowerBound, setLowerBound] = useState("");
  const [userInfo, setUserInfo] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sub = await getUserSub();
        setOwnerID(sub);

        const response = await getPoursByOwnerId(sub);
        setPours(response);

        const leavesResponse = await getLeavesByOwnerID(sub);
        // console.log(leavesResponse)
        sortLeaves(leavesResponse);
        setLeaves(leavesResponse);

        const devEUIs = leavesResponse.map((item) => item.devEUI);
        getLatestReadings(devEUIs, setLeavesReadings);

        const user = await getUserInfo(sub);
        console.log(user);
        setUserInfo(user);
        setLowerBound(String(user.thresholdLB));
        setUpperBound(String(user.thresholdUB));

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onLeafClick = (event, leaf) => {
    setShowDrawer(true);
    setSelectedLeaf(leaf);
  };

  const onNotificationsClick = () => {
    setShowDrawer(false);
    setSelectedLeaf("");
    setTab("Notifications");
  };

  const onDevicesClick = () => {
    setTab("Devices");
  };

  const handleUpperBoundChange = (e) => {
    setUpperBound(e.target.value);
  };

  const handleLowerBoundChange = (e) => {
    setLowerBound(e.target.value);
  };

  const handleThresholdSave = async (e) => {
    const updatedUserInfo = {
      ...userInfo,
      thresholdLB: Number(lowerBound),
      thresholdUB: Number(upperBound),
    };
    console.log(updatedUserInfo);
    await updateUserInfo(updatedUserInfo);
  };

  const LeafDrawer = () => {
    const [battVoltage, setBattVoltage] = useState(undefined);
    const [statusDate, setStatusDate] = useState("");
    const selectedReading = leavesReadings[selectedLeaf.devEUI];

    const filteredPours = pours.filter(
      (pour) => pour.leafDevEUI === selectedLeaf.devEUI
    );
    const selectedLeafPours = filteredPours.sort(
      (a, b) => new Date(b.startDate) - new Date(a.startDate)
    );

    useEffect(() => {
      if (selectedReading !== undefined) {
        setBattVoltage(selectedReading.battery);

        setStatusDate(formatDate(selectedReading.createdAt));
      } else {
        setBattVoltage(3.3);
        setStatusDate("There are no readings from this device.");
      }
    }, [showDrawer]);

    const BattIcon = () => {
      // Change/calibrate based on readings
      if (battVoltage >= 2.8) {
        return (
          <FontAwesomeIcon
            icon={faBatteryFull}
            size="1x"
            style={{ transform: "rotate(-90deg)" }}
          />
        );
      } else if (battVoltage >= 2.5) {
        return (
          <FontAwesomeIcon
            icon={faBatteryThreeQuarters}
            size="1x"
            style={{ transform: "rotate(-90deg)" }}
          />
        );
      } else if (battVoltage >= 2.1) {
        return (
          <FontAwesomeIcon
            icon={faBatteryHalf}
            size="1x"
            style={{ transform: "rotate(-90deg)" }}
          />
        );
      } else if (battVoltage >= 1.8) {
        return (
          <FontAwesomeIcon
            icon={faBatteryQuarter}
            size="1x"
            style={{ transform: "rotate(-90deg)" }}
          />
        );
      } else if (battVoltage < 1.8) {
        return (
          <FontAwesomeIcon
            icon={faBatteryEmpty}
            size="1x"
            style={{ transform: "rotate(-90deg)" }}
          />
        );
      } else {
        return;
      }
    };

    const onCloseClick = () => {
      setShowDrawer(false);
    };

    return (
      <VStack
        w={{ base: "90%", md: "600px" }}
        h="full"
        border="1px"
        borderLeft={{ base: "1px", lg: "0px" }}
        borderColor="gray.200"
        padding="15px"
        alignItems="start"
      >
        <HStack w="full">
          <Heading fontSize="30px">{selectedLeaf.alias}</Heading>
          <Spacer />
          <Button variant="ghost" onClick={onCloseClick}>
            <CloseIcon w={4} h={4} />
          </Button>
        </HStack>
        <Stack w="full" justifyContent="center">
          <Divider size="1px" borderColor="gray.200" mt="10px" mb="10px" />
        </Stack>

        <VStack w="full" alignItems="start">
          <Text>Leaf SN: {selectedLeaf.leafSN}</Text>
          <Text>Last Update: {statusDate}</Text>
          <Text>
            Battery: <BattIcon />
          </Text>
          <Divider size="1px" borderColor="gray.200" mt="10px" mb="10px" />
          <VStack w="full" justifyContent="center">
            <Text textAlign="center" fontWeight="bold" fontSize="2xl">
              Associated Pours
            </Text>
            {selectedLeafPours.length > 0 ? (
              <TableContainer w="full">
                <Table w="full">
                  <Thead>
                    <Tr>
                      <Th>Project</Th>
                      <Th>Floor</Th>
                      <Th>Location</Th>
                      <Th>Start Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedLeafPours.map((pour) => (
                      <Tr key={pour.pourID}>
                        <Td>{pour.project}</Td>
                        <Td>{pour.floor}</Td>
                        <Td>{pour.location}</Td>
                        <Td>{formatDate(pour.startDate)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Text>There are no pours associated with this device.</Text>
            )}
          </VStack>
        </VStack>
      </VStack>
    );
  };

  const TabStack = () => {
    // Deprecated after notifications was moved to account page,
    //  only used in the NotificationsDisplay function
    const clickedStyle = {
      backgroundColor: "white",
      color: "black",
      borderBottom: "4px solid black",
      borderRadius: "0px",
    };

    const unselectedStyle = {
      backgroundColor: "white",
      color: "black",
      borderBottom: "4px solid transparent",
      borderRadius: "0px",
    };

    return (
      <Box w="300px">
        <HStack border="1px" borderColor="gray.200" spacing={0}>
          <Button
            w="full"
            style={tabName === "Devices" ? clickedStyle : unselectedStyle}
            onClick={onDevicesClick}
          >
            Devices
          </Button>
          <Button
            w="full"
            style={tabName === "Notifications" ? clickedStyle : unselectedStyle}
            onClick={onNotificationsClick}
          >
            Notifications
          </Button>
        </HStack>
      </Box>
    );
  };

  const DevicesDisplay = () => {
    return showDrawer ? (
      // Drawer open
      <>
        <Box h="full" display={{ base: "none", lg: "flex" }}>
          <HStack m="20px" w="980px" spacing={0} alignItems="start" mt={2}>
            <ButtonStack
              leaves={leaves}
              leavesReadings={leavesReadings}
              onLeafClick={onLeafClick}
            />
            <LeafDrawer />
          </HStack>
        </Box>
        <Box
          w="full"
          h="full"
          display={{ base: "flex", lg: "none" }}
          justifyContent="center"
          mt={2}
        >
          <LeafDrawer />
        </Box>
      </>
    ) : (
      // Drawer closed
      <>
        <Flex h="full" w="full" mt={2} justifyContent="center">
          <ButtonStack
            leaves={leaves}
            leavesReadings={leavesReadings}
            onLeafClick={onLeafClick}
          />
        </Flex>
      </>
    );
  };

  const NotificationsDisplay = () => {
    // Deprecated after moving this to the account page
    return (
      <>
        <TabStack />
        <VStack
          h="full"
          w={{ base: "90%", sm: "480px" }}
          p="15px"
          border="1px"
          borderColor="gray.200"
        >
          <Box>
            <Text>
              In this tab you can setup notification thresholds. Notifications
              will be sent by email when the temperature on your device crosses
              one of the set thresholds below. The below thresholds will be set
              for all pours that you have created on this account.
            </Text>
          </Box>
          <FormControl>
            <FormLabel>Maximum temperature (°C)</FormLabel>
            <Input
              value={upperBound}
              onChange={handleUpperBoundChange}
              type="number"
              placeholder="e.g. 25 °C"
            />

            <FormLabel>Minimum temperature (°C)</FormLabel>
            <Input
              value={lowerBound}
              onChange={handleLowerBoundChange}
              type="number"
              placeholder="e.g. 25 °C"
            />
          </FormControl>
          <Button onClick={handleThresholdSave} w="80%" mt="10px">
            Save
          </Button>
        </VStack>
      </>
    );
  };

  return (
    <Container maxW="full" h="100vh" margin="0" padding="0" overflowX="hidden">
      <VStack w="full" h="full" spacing={0}>
        <MainNav />
        <VStack
          w="100vw"
          h="full"
          margin="0"
          padding={0}
          pt="48px"
          pb="50px"
          overflowX="hidden"
          overflowY="auto"
        >
          <Heading marginTop={{ base: "10px", lg: "20px" }}>Devices</Heading>
          <DevicesDisplay />
        </VStack>
        <MobileMainNav />
      </VStack>
    </Container>
  );
};

const ButtonStack = ({ leaves, leavesReadings, onLeafClick }) => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [queryLeaves, setQueryLeaves] = useState([]);

  useEffect(() => {
    const filteredData = leaves.filter((item) => {
      return item.alias.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setQueryLeaves(filteredData);
  }, [leaves, searchQuery]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <VStack
      h="full"
      w={{ base: "90%", sm: "380px" }}
      p="15px"
      border="1px"
      borderColor="gray.200"
    >
      <Input
        id="search-input"
        placeholder="Search"
        size="lg"
        value={searchQuery}
        onChange={handleSearch}
      />
      <Divider size="1px" borderColor="gray.200" margin="10px" />
      <VStack
        h="full"
        w="full"
        flexGrow={1}
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#c2c2c2",
            borderRadius: "24px",
          },
        }}
      >
        {queryLeaves.map((leaf, index) => (
          <LeafButton
            key={index}
            leafAlias={leaf.alias}
            onClick={(event) => onLeafClick(event, leaf)}
            latestReading={leavesReadings[leaf.devEUI]}
          />
        ))}
      </VStack>
      <Button
        h="40px"
        w="full"
        mt="8px"
        onClick={() => navigate("/device_registration")}
      >
        + Add New Sensor
      </Button>
    </VStack>
  );
};

export default DeviceManager;
