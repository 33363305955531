import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import './forgotpw.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState (1);
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState ('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Simple regex for email validation
    return /\S+@\S+\.\S+/.test(email);
  };

  const sendCode = async (event) => {
    event.preventDefault();
    if (!validateEmail(username)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      await Auth.forgotPassword(username);
      setStep(2); // Move to the next step
      setError(''); // Clear any previous errors
    } catch (error) {
      setError(error.message);
    }
  };


  const resetPassword = async() =>{
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);//redirect to login or show successs message
      setSuccessMessage('Password reset successfully. You will be redirected to the login page in 3 seconds.');
      setError(''); // Clear any errors if the password reset is successful
     
      setTimeout(() => {
        navigate('/login'); // Use the path to your login route
      }, 3000);
    } catch (error){
      setError(error.message);
      setSuccessMessage('');
    }
  };

  return (
    <div className='forgotpw-container'>
      {step === 1 && (
        <div >
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Email"
          />
          <button onClick={sendCode}>Send Code</button>
        </div>
      )}

      {step === 2 && (
        <div >
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Verification Code"
          />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
          />
          <button onClick={resetPassword}>Reset Password</button>
        </div>
      )}

      {successMessage && <p1>{successMessage}</p1>}
      {error && <p2>{error}</p2>}
    </div>
  );
};

export default ForgotPassword;