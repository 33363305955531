import React, { useState } from "react"
import Register from "../register/register" // Importing the Register component
import { Auth } from "aws-amplify"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../AuthContext"
import NewPW from "./newPW"

import {
  useToast,
  Text,
  Divider,
  Spacer,
  Flex,
  Center,
  Box,
  HStack,
  VStack,
  Link as LinkComp,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Container,
  Image,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react"

import { ArrowBackIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons"

import { MdCheckCircle, MdCancel } from "react-icons/md"

const Login = () => {
  // State for Toast Dialog
  const toast = useToast()
  const navigate = useNavigate()

  // State for hiding and showing password
  const [hidden, setHidden] = useState(true)

  const { setIsAuthenticated } = useAuth()
  //State for username and password
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isError, setIsError] = useState(false)
  const [newPW, setNewPW] = useState("")
  const [newPWFlag, setNewPWFlag] = useState(false)
  const [cogUser, setCogUser] = useState(null)
  const [pwCriteria, setPWCriteria] = useState({
    minLength: false,
    oneUpperCase: false,
    oneLowerCase: false,
    oneNumber: false,
  })

  const signIn = async (event) => {
    event.preventDefault() //Prevent default form submission behavior
    try {
      const user = await Auth.signIn(username, password)
      setCogUser(user)
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        console.log("New password required")
        setUsername("")
        setPassword("")
        setNewPW("")
        setNewPWFlag(true)
      } else {
        setIsAuthenticated(true)
        navigate("/main")
      }
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        // Handle user not confirmed error
        console.log(
          "User not confirmed. Redirect to confirmation page or resend confirmation email."
        )
      } else if (error.code === "PasswordResetRequiredException") {
        // Handle password reset required error
        console.log("Password reset required. Redirect to password reset page.")
      } else if (error.code === "NotAuthorizedException") {
        setIsError(true)
      } else {
        // Handle other errors
        console.log(error)
      }
    }
  }

  return (
    <VStack minW="100vw">
      <HStack w="100%" p={5} spacing={1} mb={[1, 8, 20, 20]}>
        <Link to="/">
          <Button
            color="gray.500"
            _hover={{ color: "gray.700" }}
            variant="link"
            fontSize="sm"
          >
            <ArrowBackIcon w={3} h={3} m={[0, 1]} />
            Return to Homepage
          </Button>
        </Link>
      </HStack>
      <Center h={500} minW="100%">
        <VStack w="100%" maxW="md">
          <Image src="oai-logo.svg" boxSize="200px" />
          <Container m={15} pr={10} pl={10}>
            <Flex minH="max-content" alignItems="center">
              <FormControl isRequired>
                {!newPWFlag ? (
                  <form onSubmit={signIn}>
                    <VStack spacing={8} align="stretch">
                      <Box>
                        <FormLabel as="email-label">
                          <Text as="b" fontSize="xs" color="gray.600">
                            Email or Username
                          </Text>
                        </FormLabel>
                        <Input
                          id="email-input"
                          name="email"
                          onChange={(e) => setUsername(e.target.value)}
                          size="sm"
                          boxShadow="sm"
                          bg="white"
                          pt={6}
                          pr={4}
                          pb={6}
                          pl={4}
                          rounded="md"
                          placeholder="Enter your email"
                        />
                      </Box>
                      <Box size="lg">
                        <FormLabel as="pw-label">
                          <Text as="b" fontSize="xs" color="gray.600">
                            Password
                          </Text>
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="pw-input"
                            name="current-password"
                            type={hidden ? "password" : "text"}
                            role="switch"
                            onChange={(e) => setPassword(e.target.value)} // Update password state on change
                            pt={6}
                            pr={4}
                            pb={6}
                            pl={4}
                            size="sm"
                            boxShadow="sm"
                            bg="white"
                            rounded="md"
                            placeholder="Enter your password"
                          />
                          <InputRightElement p="6">
                            <Button
                              onClick={() => setHidden(!hidden)}
                              color="gray.300"
                              _hover={{ color: "gray.500" }}
                              variant="link"
                            >
                              {hidden ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        {/*FIX: Temporary toast until password change page is setup*/}
                        <LinkComp
                          onClick={() =>
                            toast({
                              title:
                                "The Platform requires registration and is currently only available to pre-approved partners and customers",
                              position: "bottom-right",
                              variant: "subtle",
                              colorScheme: "oai.white",
                              status: "info",
                              isClosable: true,
                              duration: 5000,
                              containerStyle: {
                                fontSize: "14px",
                              },
                            })
                          }
                        >
                          <FormHelperText>Forgot your password?</FormHelperText>
                        </LinkComp>
                        {isError ? (
                          <Text mt={2} color="red" fontSize="12px">
                            Invalid Email/Username or password
                          </Text>
                        ) : null}
                      </Box>
                      <Divider orientation="horizontal" />
                      <Flex>
                        <Button
                          // onClick={signIn}
                          className="logInBtn" // Necessary for button click events to be differentiable
                          type="submit"
                          mr={2}
                          variant="solid"
                          colorScheme="yellow"
                          color="white"
                          w="full"
                        >
                          Log in
                        </Button>
                        <Spacer />
                        {/*FIX: Temporary toast until create account page is setup*/}
                        <Button
                          onClick={() =>
                            toast({
                              title:
                                "The Platform requires registration and is currently only available to pre-approved partners and customers",
                              position: "bottom-right",
                              variant: "subtle",
                              colorScheme: "oai.white",
                              status: "info",
                              isClosable: true,
                              duration: 5000,
                              containerStyle: {
                                fontSize: "14px",
                              },
                            })
                          }
                          className="createAccBtn" // Necessary for button click events to be differentiable
                          ml={2}
                          variant="ghost"
                          w="full"
                        >
                          Create Account
                        </Button>
                      </Flex>
                    </VStack>
                  </form>
                ) : (
                  <NewPW cogUser={cogUser} setNewPWFlag={setNewPWFlag} />
                )}
              </FormControl>
            </Flex>
          </Container>
        </VStack>
      </Center>
    </VStack>
  )
}

export default Login //exporting the login component
