/**
 * LeafButton.js
 * Author: Michael Bacani
 * Last Updated: 2024-04-10
 * Displays data associated to a single device in a button format
 */

import { useEffect, useState, useMemo } from "react"

import { Button, HStack, Text, Badge } from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
} from "@fortawesome/free-solid-svg-icons"

const LeafButton = ({ leafAlias, onClick, latestReading }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [battVoltage, setBattVoltage] = useState(undefined)
  const [activity, setActivity] = useState(true)

  useEffect(() => {
    if (latestReading === undefined) {
      setBattVoltage(undefined)
    } else {
      setBattVoltage(latestReading.battery)
      const datetime = new Date(latestReading.createdAt)

      // The sampling frequency is ~15mins
      datetime.setMinutes(datetime.getMinutes() + 16)
      const now = new Date()

      if (now >= datetime) {
        setActivity(false)
      } else {
        setActivity(true)
      }
      setIsLoading(false)
    }
  }, [latestReading])

  // const battIcon = useMemo(() => {
  //   // Change/calibrate based on readings
  //   if (battVoltage >= 2.8) {
  //     return (
  //       <FontAwesomeIcon
  //         icon={faBatteryFull}
  //         size="1x"
  //         style={{ transform: "rotate(-90deg)" }}
  //       />
  //     )
  //   } else if (battVoltage >= 2.5) {
  //     return (
  //       <FontAwesomeIcon
  //         icon={faBatteryThreeQuarters}
  //         size="1x"
  //         style={{ transform: "rotate(-90deg)" }}
  //       />
  //     )
  //   } else if (battVoltage >= 2.1) {
  //     return (
  //       <FontAwesomeIcon
  //         icon={faBatteryHalf}
  //         size="1x"
  //         style={{ transform: "rotate(-90deg)" }}
  //       />
  //     )
  //   } else if (battVoltage >= 1.8) {
  //     return (
  //       <FontAwesomeIcon
  //         icon={faBatteryQuarter}
  //         size="1x"
  //         style={{ transform: "rotate(-90deg)" }}
  //       />
  //     )
  //   } else if (battVoltage < 1.8) {
  //     return (
  //       <FontAwesomeIcon
  //         icon={faBatteryEmpty}
  //         size="1x"
  //         style={{ transform: "rotate(-90deg)" }}
  //       />
  //     )
  //   } else {
  //     return
  //   }
  // }, [battVoltage])
  
  return (
    <Button
      w="95%"
      minH="70px"
      bg=""
      border="1px"
      borderColor="gray.200"
      onClick={onClick}
    >
      <HStack w="full" justifyContent="space-between">
        <HStack>
          <Text>{leafAlias}</Text>
        </HStack>
        <HStack>
          {isLoading ? null : activity ? (
            <Badge colorScheme="green" padding="6px">
              Active
            </Badge>
          ) : (
            <Badge colorScheme="yellow" padding="6px">
              Inactive
            </Badge>
          )}

          {/* {battIcon} */}
          <ArrowForwardIcon />
        </HStack>
      </HStack>
    </Button>
  )
}

export default LeafButton
