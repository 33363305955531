/*
 * EditPourModal.js
 * coop@arbor-iot.com
 * 2024-04-09
 * Modal component containing a form used to edit a pour's properties
 */
import React, { useState, useEffect } from "react" //import hooks for managing state and side effects

import {
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react"

// Services
import {
  getPoursByOwnerId,
  updatePour,
} from "../../../services/PourInfoService.js"

// Helpers
import { filterTable } from "../../../utils/TableFilters.js"

const EditPourModal = ({
  pours,
  leaves,
  ownerID,
  selectedPour,
  selectedLeaf,
  setSelectedPour,
  setSelectedLeaf,
  setPours,
  isEditOpen,
  onEditClose,
  setProject,
  setFloor,
  setLocation,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [propAlias, setPropAlias] = useState("")
  const [propProject, setPropProject] = useState("")
  const [propFloor, setPropFloor] = useState("")
  const [propLocation, setPropLocation] = useState("")
  const [propDate, setPropDate] = useState("")

  useEffect(() => {
    setPropAlias(selectedLeaf.alias)
    setPropProject(selectedPour.project)
    setPropFloor(selectedPour.floor)
    setPropLocation(selectedPour.location)
    if (selectedPour.startDate) {
      setPropDate(selectedPour.startDate.slice(0, 16)) // Fix for chakra-ui date format
    } else {
      setPropDate(selectedPour.startDate)
    }
  }, [selectedLeaf, selectedPour])

  const handlePropAlias = (e) => {
    setPropAlias(e.target.value)
  }
  const handlePropProject = (e) => {
    setPropProject(e.target.value)
  }
  const handlePropFloor = (e) => {
    setPropFloor(e.target.value)
  }
  const handlePropLocation = (e) => {
    setPropLocation(e.target.value)
  }
  const handlePropDate = (e) => {
    //Fix for date time format
    setPropDate(e.target.value)
  }

  const handleEditProp = async () => {
    if (Object.keys(selectedPour).length === 0) return
    if (!(propProject && propFloor && propLocation && propAlias && propDate))
      return

    const [leaf] = filterTable(leaves, {
      alias: propAlias,
    })

    const [hasPour] = filterTable(pours, {
      leafDevEUI: leaf.devEUI,
      project: propProject,
      floor: propFloor,
      location: propLocation,
      startDate: propDate + ":00.000Z", // Fix for formatting issue with chakra-ui date picker
    })

    // Pour already exists
    if (hasPour) {
      console.log("POUR WITH PROPS ALREADY EXISTS")
      return
    }

    const tempPour = {
      pourID: selectedPour.pourID,
      ownerID: selectedPour.ownerID,
      endDate: selectedPour.endDate,
      leafDevEUI: leaf.devEUI,
      project: propProject,
      floor: propFloor,
      location: propLocation,
      startDate: propDate + ":00.000Z", // Fix for formatting issue with chakra-ui date picker
    }

    setIsLoading(true)
    setSelectedPour(tempPour)
    setSelectedLeaf(leaf)

    setProject(propProject)
    setFloor(propFloor)
    setLocation(propLocation)

    await updatePour(tempPour)
    const response = await getPoursByOwnerId(ownerID)
    setPours(response)

    setIsLoading(false)
    onEditClose()
  }

  return (
    <Modal isOpen={isEditOpen} onClose={onEditClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Pour</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Device</FormLabel>
            <Select
              onChange={handlePropAlias}
              defaultValue={propAlias}
              placeholder="Select a leaf"
            >
              {leaves.map((leaf) => (
                <option key={leaf.alias} value={leaf.alias}>
                  {leaf.alias}
                </option>
              ))}
            </Select>

            <FormLabel id="project-label" pt={4}>Project</FormLabel>
            <Input id="project-input" value={propProject} onChange={handlePropProject} />

            <FormLabel id="floor-label" pt={4}>Floor</FormLabel>
            <Input id="floor-input" value={propFloor} onChange={handlePropFloor} />

            <FormLabel id="location-label" pt={4}>Location</FormLabel>
            <Input id="location-input" value={propLocation} onChange={handlePropLocation} />

            <FormLabel id="start-date-label" pt={4}>Start Date</FormLabel>
            <Input
              id="start-date-input"
              value={propDate}
              onChange={handlePropDate}
              placeholder="Select Date and Time"
              type="datetime-local"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            fontWeight="medium"
            mr={3}
            onClick={onEditClose}
          >
            Close
          </Button>
          {isLoading ? (
            <Button
              leftIcon={
                <Spinner
                  thickness="2px"
                  speed="1.0s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              }
              variant="ghost"
              fontWeight="medium"
            >
              Loading...
            </Button>
          ) : (
            <Button
              variant="ghost"
              fontWeight="medium"
              onClick={handleEditProp}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditPourModal
