import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import AppWrapper from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";

// Import global fonts
import "@fontsource/raleway/400.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/kanit/500.css";
import "@fontsource/niramit/400.css";
import "@fontsource/niramit/700.css";

import { ChakraProvider } from "@chakra-ui/react";

import axios from "axios";

import theme from "./Theme";

// import dotenv from "dotenv"

// dotenv.config()

Amplify.configure(config);

// Configure Default Headers for all requests
axios.defaults.headers.common["X-Api-Key"] = process.env.REACT_APP_X_API_KEY;
axios.defaults.baseURL =
  "https://0z9beptyma.execute-api.us-east-1.amazonaws.com/Prod";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <AppWrapper />
    </React.StrictMode>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
