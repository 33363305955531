/*
 * UserInfoService.js
 * coop@arbor-iot.com
 * 2024-04-09
 * Wrapper around API endpoints, providing basic CRUD operations for managing data within the LeafInfoTable
 * Also contains utilities to handle data retrieval from Amplify Auth
 */
import { Auth } from "aws-amplify";
import axios from "axios";

export const getUserCredentials = async () => {
  const userInfo = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  return userInfo;
};

export const getUserSub = async () => {
  const userInfo = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  return userInfo.attributes.sub;
};

/**
 * Gets user with associated ownerID
 * @param {String} ownerID  Cognito user sub
 * @returns {Array}         Returns object containing the users account settings
 */
export const getUserInfo = async (ownerID) => {
  try {
    const { data: response } = await axios.get("/user/", {
      params: { ownerID },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserInfo = async (userInfo) => {
  try {
    const { data: response } = await axios.put("/user/", userInfo);
    return response;
  } catch (error) {
    console.log(error);
  }
};
