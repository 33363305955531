/**
 * main.js
 * Author: Michael Bacani
 * Last Updated: 2024-07-09
 * Uses filter options for selecting pours based on project floor, and
 * location. Displays data from each pour's attatched leaf.
 */

import React, { useState, useEffect } from "react";

// Custom UI components
import KPIDisplay from "./components/KPIDisplay.js";
import CreatePourModal from "./components/CreatePourModal.js";
import EditPourModal from "./components/EditPourModal.js";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Container,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Stack,
  Button,
  Tooltip,
  VStack,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  Box,
  useToast,
  useBreakpointValue,
  Collapse,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  CloseButton,
} from "@chakra-ui/react";

import { FaCalendar } from "react-icons/fa6";
import { MdSensors } from "react-icons/md";

import { CloseIcon, HamburgerIcon, RepeatIcon } from "@chakra-ui/icons";

// Chart
import GraphDisplay from "./components/GraphDisplay";

// Helpers
import { filterTable } from "../../utils/TableFilters.js";
import {
  formatDate,
  sortLeaves,
  sortPours,
} from "../../utils/OpusenseHelpers.js";

// Services
import {
  getPoursByOwnerId,
  updatePour,
} from "../../services/PourInfoService.js";

import { getLeavesByOwnerID } from "../../services/LeafInfoService.js";

import { getLeafData } from "../../services/LeafDynService.js";

import { getUserSub } from "../../services/UserInfoService.js";
import EndPourModal from "./components/EndPourModal.js";
import { MainNav, MobileMainNav } from "../components/MainNav.js";

const MainPage = () => {
  // State to manage loading component
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();

  // Chakra Hooks for Modals
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const {
    isOpen: isNewOpen,
    onOpen: onNewOpen,
    onClose: onNewClose,
  } = useDisclosure();

  const {
    isOpen: isEndOpen,
    onOpen: onEndOpen,
    onClose: onEndClose,
  } = useDisclosure();

  // Auth attributes
  const [ownerID, setOwnerID] = useState("");

  // UI Utilities
  const [filterMenu, setFilterMenu] = useState(true);
  const [selectedAccordion, setSelectedAccordion] = useState(0);
  const handleAccordionSelect = (index) => {
    setSelectedAccordion(index);
  };

  // Cache server data within components state
  const [pours, setPours] = useState([]);
  const [leaves, setLeaves] = useState([]);

  // Current Selected Options
  const [selectedPour, setSelectedPour] = useState({});
  // Leaf from selectedPour leafDevEUI
  const [selectedLeaf, setSelectedLeaf] = useState({});
  const [project, setProject] = useState("");
  const [floor, setFloor] = useState("");
  const [location, setLocation] = useState("");

  // Sidebar Options
  const [projectOpts, setProjectOpts] = useState([]);
  const [floorOpts, setFloorOpts] = useState([]);
  const [locationOpts, setLocationOpts] = useState([]);

  // Graph Data
  const [leafData, setLeafData] = useState([]);
  const [graphType, setGraphType] = useState("conTemp");

  // Get user info from Cognito service
  useEffect(() => {
    const getCognitoSub = async () => {
      const sub = await getUserSub();
      setOwnerID(sub);
    };
    getCognitoSub();
  }, []);

  // Store database items with matching ownerID to be used locally
  useEffect(() => {
    const pourInit = async () => {
      try {
        setIsLoading(true);
        const response = await getPoursByOwnerId(ownerID);
        sortPours(response);
        setPours(response);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    const leavesInit = async () => {
      try {
        setIsLoading(true);
        const response = await getLeavesByOwnerID(ownerID);
        sortLeaves(response);
        setLeaves(response);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    pourInit();
    leavesInit();
  }, [ownerID]);

  // Filter project options
  useEffect(() => {
    let tempOpts = new Set();
    pours.forEach((pour) => {
      tempOpts.add(pour.project);
    });
    setProjectOpts([...tempOpts]);
  }, [pours]);

  // Filter flooor options
  useEffect(() => {
    let tempOpts = new Set();
    const response = filterTable(pours, { project });

    response.forEach((pour) => {
      tempOpts.add(pour.floor);
    });

    setFloorOpts([...tempOpts]);
  }, [pours, project]);

  // Filter flooor options
  useEffect(() => {
    let tempOpts = new Set();
    const response = filterTable(pours, { project, floor });

    response.forEach((pour) => {
      tempOpts.add(pour.location);
    });

    setLocationOpts([...tempOpts]);
  }, [pours, project, floor]);

  const handleProjectSelect = (e) => {
    const value = e.target.value;
    setProject(value);
    setFloor("");
    setLocation("");
    setSelectedAccordion(1);
  };

  const handleFloorSelect = (e) => {
    const value = e.target.value;
    setFloor(value);
    setLocation("");
    setSelectedAccordion(2);
  };

  const handleLocationSelect = (e) => {
    const value = e.target.value;
    setLocation(value);
    setSelectedAccordion(3);
  };

  const handleSelectPour = (e) => {
    if (!project || !floor || !location) return;

    const [pour] = filterTable(pours, {
      project: project,
      floor: floor,
      location: location,
    });
    setSelectedPour(pour);

    const [leaf] = filterTable(leaves, {
      devEUI: pour.leafDevEUI,
    });
    setSelectedLeaf(leaf);

    const fetchData = async () => {
      const data = await getLeafData(pour);
      if (data) setLeafData(data);
    };
    fetchData();
  };

  const handleRefresh = (e) => {
    if (Object.keys(selectedPour).length === 0) return;
    const fetchData = async () => {
      const data = await getLeafData(selectedPour);
      if (data) setLeafData(data);
    };
    fetchData();
  };

  const handleEndPour = () => {
    if (selectedPour.endDate) {
      toast({
        title: "Can't end the pour",
        body: "The pour has already ended",
        position: "bottom-right",
        variant: "subtle",
        colorScheme: "oai.white",
        status: "info",
        isClosable: true,
        duration: 5000,
        containerStyle: {
          fontSize: "14px",
        },
      });
    } else {
      onEndOpen();
    }
  };

  return (
    <Container maxW="full" h="100vh" margin="0" padding="0" overflowX="hidden">
      <Box w="full" h="full" display={{ base: "none", md: "flex " }}>
        <Box w="full" h="full">
          <MainNav />
          <HStack w="full" h="full" spacing={0} margin={0} p={0} pt="48px">
            {/* Sidebar */}
            {filterMenu ? (
              <VStack
                borderRight="1px"
                borderStyle="solid"
                borderColor="gray.200"
                width="300px"
                height="full"
              >
                <HStack
                  w="full"
                  justify="space-between"
                  align="center"
                  pt={2}
                  pl={4}
                  pr={2}
                >
                  <Text fontSize={20} fontWeight="600">
                    Select A Pour
                  </Text>
                  <CloseButton
                    onClick={() => setFilterMenu(false)}
                    w={10}
                    h={10}
                  />
                </HStack>

                <Accordion width="100%" allowToggle index={selectedAccordion}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        fontWeight="600"
                        onClick={() => handleAccordionSelect(0)}
                      >
                        <HStack w="full" justify="space-between">
                          <Text>Project</Text>
                          <Text color="gray.600">{project}</Text>
                        </HStack>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p="0px">
                      <Stack direction="column">
                        {projectOpts.map((label) => (
                          <Button
                            key={label}
                            value={label}
                            bg="None"
                            justifyContent="left"
                            p="15px"
                            rounded="0px"
                            fontWeight="400"
                            onClick={handleProjectSelect}
                          >
                            {label}
                          </Button>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        fontWeight="600"
                        onClick={() => handleAccordionSelect(1)}
                      >
                        <HStack w="full" justify="space-between">
                          <Text>Floor</Text>
                          <Text color="gray.600">{floor}</Text>
                        </HStack>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p="0px">
                      <Stack direction="column">
                        {floorOpts.map((label) => (
                          <Button
                            key={label}
                            value={label}
                            bg="None"
                            justifyContent="left"
                            p="15px"
                            rounded="0px"
                            fontWeight="400"
                            onClick={handleFloorSelect}
                          >
                            {label}
                          </Button>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        fontWeight="600"
                        onClick={() => handleAccordionSelect(2)}
                      >
                        <HStack w="full" justify="space-between">
                          <Text>Location</Text>
                          <Text color="gray.600">{location}</Text>
                        </HStack>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p="0px">
                      <Stack direction="column">
                        {locationOpts.map((label) => (
                          <Button
                            key={label}
                            value={label}
                            bg="None"
                            justifyContent="left"
                            p="15px"
                            rounded="0px"
                            fontWeight="400"
                            onClick={handleLocationSelect}
                          >
                            {label}
                          </Button>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <Button
                  width="150px"
                  variant="outline"
                  onClick={handleSelectPour}
                >
                  Select Pour
                </Button>
                <Button width="150px" variant="outline" onClick={onNewOpen}>
                  Create Pour
                </Button>

                <CreatePourModal
                  pours={pours}
                  leaves={leaves}
                  ownerID={ownerID}
                  setPours={setPours}
                  isNewOpen={isNewOpen}
                  onNewClose={onNewClose}
                />
              </VStack>
            ) : (
              <HStack
                width="55px"
                height="full"
                borderRight="1px"
                borderStyle="solid"
                borderColor="gray.200"
                justify="center"
                align="flex-start"
                pt={2}
              >
                <Button
                  onClick={() => setFilterMenu(true)}
                  w={10}
                  h={10}
                  bg="none"
                >
                  <HamburgerIcon />
                </Button>
              </HStack>
            )}

            {/* Data Display */}
            <VStack w="full" h="full" spacing={0}>
              <HStack
                py={2}
                px={4}
                w="full"
                h="130px"
                justify="space-between"
                borderBottom="1px"
                borderStyle="solid"
                borderColor="gray.200"
              >
                <VStack align="start">
                  <HStack
                    py={1}
                    px={2}
                    borderRadius="6px"
                    border="1px"
                    borderStyle="solid"
                    borderColor="gray.200"
                    spacing={0}
                  >
                    <Tooltip
                      placement="right-start"
                      label={
                        Object.keys(selectedPour).length
                          ? ""
                          : "Select a pour from the menu"
                      }
                      aria-label="A tooltip"
                    >
                      {/* Wrap in span as fa icons don't use forward ref*/}
                      <span>
                        <Icon as={MdSensors} boxSize={4} />
                      </span>
                    </Tooltip>
                    {Object.keys(selectedLeaf).length ? (
                      <Text pl={2}>{selectedLeaf.alias}</Text>
                    ) : (
                      ""
                    )}
                  </HStack>
                  <HStack
                    py={1}
                    px={2}
                    borderRadius="6px"
                    border="1px"
                    borderStyle="solid"
                    borderColor="gray.200"
                    spacing={0}
                  >
                    <Tooltip
                      placement="right-start"
                      label={
                        Object.keys(selectedPour).length
                          ? ""
                          : "Select a pour from the menu"
                      }
                      aria-label="A tooltip"
                    >
                      {/* Wrap in span as fa icons don't use forward ref*/}
                      <span>
                        <Icon as={FaCalendar} boxSize={4} />
                      </span>
                    </Tooltip>
                    {Object.keys(selectedLeaf).length ? (
                      <Text pl={2}>
                        {formatDate(selectedPour.startDate)}
                        {selectedPour.endDate
                          ? ` - ${formatDate(selectedPour.endDate)}`
                          : ""}
                      </Text>
                    ) : (
                      ""
                    )}
                  </HStack>
                </VStack>
                <VStack align="end">
                  <Button onClick={handleEndPour} px={4} variant="outline">
                    End Pour
                  </Button>
                  <EndPourModal
                    selectedPour={selectedPour}
                    setSelectedPour={setSelectedPour}
                    ownerID={ownerID}
                    setPours={setPours}
                    isEndOpen={isEndOpen}
                    onEndClose={onEndClose}
                  />
                  <Button onClick={onEditOpen} px={4} variant="outline">
                    Edit Properties
                  </Button>
                  <EditPourModal
                    pours={pours}
                    leaves={leaves}
                    ownerID={ownerID}
                    selectedPour={selectedPour}
                    selectedLeaf={selectedLeaf}
                    setSelectedPour={setSelectedPour}
                    setSelectedLeaf={setSelectedLeaf}
                    setPours={setPours}
                    isEditOpen={isEditOpen}
                    onEditClose={onEditClose}
                    setProject={setProject}
                    setFloor={setFloor}
                    setLocation={setLocation}
                  />
                </VStack>
              </HStack>
              <Box w="full" display={{ base: "flex", lg: "none" }}>
                <KPIDisplay leafData={leafData} />
              </Box>
              <HStack h="full" w="full" spacing={0}>
                <Box h="full" display={{ base: "none", lg: "flex" }}>
                  <KPIDisplay leafData={leafData} />
                </Box>
                <VStack h="full" w="full" spacing={0}>
                  <HStack p={2} w="full">
                    <Menu>
                      <MenuButton
                        px={4}
                        as={IconButton}
                        aria-label="Options"
                        variant="outline"
                      >
                        {graphType === "conTemp"
                          ? "Concrete Temperature"
                          : "Ambient Temperature"}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            setGraphType("conTemp");
                          }}
                        >
                          Concrete Temperature
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setGraphType("ambTemp");
                          }}
                        >
                          Ambient Temperature
                        </MenuItem>
                        <MenuItem>Strength </MenuItem>
                      </MenuList>
                    </Menu>
                    {isLoading ? (
                      <IconButton
                        aria-label="Options"
                        icon={<RepeatIcon />}
                        variant="outline"
                      />
                    ) : (
                      <IconButton
                        aria-label="Options"
                        icon={<RepeatIcon />}
                        variant="outline"
                        onClick={handleRefresh}
                      />
                    )}
                  </HStack>
                  <Center h="full" w="full">
                    <Container maxW="90%" h="90%">
                      <GraphDisplay
                        leafData={leafData}
                        graphType={graphType}
                        pourData={selectedPour}
                      />
                    </Container>
                  </Center>
                </VStack>
              </HStack>
            </VStack>
          </HStack>
        </Box>
      </Box>
      <Box w="full" display={{ base: "flex", md: "none" }}>
        <VStack w="full" h="full" spacing={0}>
          <MainNav />
          <Container
            minW="100vw"
            h="full" // Height of both navbars
            margin="0"
            padding={0}
            pt="48px"
            pb="50px"
            overflowX="hidden"
            overflowY="auto"
            sx={{
              WebkitOverflowScrolling: "touch",
            }}
          >
            <Box w="full">
              <Button
                onClick={onToggle}
                w="full"
                borderRadius={0}
                borderBottom="1px"
                borderColor="gray.200"
                backgroundColor="white"
              >
                <Text>Select A Pour</Text>
                <Spacer />
                {isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )}
              </Button>
            </Box>
            <Collapse in={isOpen} animateOpacity>
              <VStack bt={0} width="full" height="full">
                <Accordion width="100%" allowToggle index={selectedAccordion}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        fontWeight="600"
                        onClick={() => handleAccordionSelect(0)}
                      >
                        <HStack w="full" justify="space-between">
                          <Text>Project</Text>
                          <Text color="gray.600">{project}</Text>
                        </HStack>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p="0px">
                      <Stack direction="column">
                        {projectOpts.map((label) => (
                          <Button
                            key={label}
                            value={label}
                            bg="None"
                            justifyContent="left"
                            p="15px"
                            rounded="0px"
                            fontWeight="400"
                            onClick={handleProjectSelect}
                          >
                            {label}
                          </Button>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        fontWeight="600"
                        onClick={() => handleAccordionSelect(1)}
                      >
                        <HStack w="full" justify="space-between">
                          <Text>Floor</Text>
                          <Text color="gray.600">{floor}</Text>
                        </HStack>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p="0px">
                      <Stack direction="column">
                        {floorOpts.map((label) => (
                          <Button
                            key={label}
                            value={label}
                            bg="None"
                            justifyContent="left"
                            p="15px"
                            rounded="0px"
                            fontWeight="400"
                            onClick={handleFloorSelect}
                          >
                            {label}
                          </Button>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        fontWeight="600"
                        onClick={() => handleAccordionSelect(2)}
                      >
                        <HStack w="full" justify="space-between">
                          <Text>Location</Text>
                          <Text color="gray.600">{location}</Text>
                        </HStack>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p="0px">
                      <Stack direction="column">
                        {locationOpts.map((label) => (
                          <Button
                            key={label}
                            value={label}
                            bg="None"
                            justifyContent="left"
                            p="15px"
                            rounded="0px"
                            fontWeight="400"
                            onClick={handleLocationSelect}
                          >
                            {label}
                          </Button>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <HStack
                  borderBottom="2px"
                  borderColor="gray.200"
                  pb={4}
                  px={4}
                  w="full"
                >
                  <Button w="full" variant="outline" onClick={handleSelectPour}>
                    Select Pour
                  </Button>
                  <Button w="full" variant="outline" onClick={onNewOpen}>
                    Create Pour
                  </Button>
                </HStack>
                <CreatePourModal
                  pours={pours}
                  leaves={leaves}
                  ownerID={ownerID}
                  setPours={setPours}
                  isNewOpen={isNewOpen}
                  onNewClose={onNewClose}
                />
              </VStack>
            </Collapse>

            <HStack
              h="100px"
              w="full"
              spacing={0}
              borderBottom="1px"
              borderColor="gray.200"
              my={2}
            >
              <Center w="full" h="full" align="start" py={2} px={4}>
                <Stat>
                  <StatLabel>Concrete Temperature</StatLabel>
                  <StatNumber>
                    {leafData.length
                      ? Math.round(leafData[leafData.length - 1].temp * 10) /
                          10 +
                        "ºC"
                      : "-"}
                  </StatNumber>
                </Stat>
              </Center>
              <Center w="full" h="full" align="start" py={2} px={4}>
                <Stat>
                  <StatLabel>Ambient Temperature</StatLabel>
                  <StatNumber>
                    {leafData.length
                      ? Math.round(leafData[leafData.length - 1].temp2 * 10) /
                          10 +
                        "ºC"
                      : "-"}
                  </StatNumber>
                </Stat>
              </Center>
              <Center w="full" h="full" align="start" py={2} px={4}>
                <Stat>
                  <StatLabel>Strength</StatLabel>
                  <StatNumber>{leafData.length ? "-" : "-"}</StatNumber>
                </Stat>
              </Center>
            </HStack>
            <HStack w="full" p={2}>
              <Spacer />
              <Button px={8} variant="outline">
                Edit Pour
              </Button>
              <Button onClick={handleEndPour} px={8} mr={2} variant="outline">
                End Pour
              </Button>
            </HStack>
            <EndPourModal
              selectedPour={selectedPour}
              setSelectedPour={setSelectedPour}
              ownerID={ownerID}
              setPours={setPours}
              isEndOpen={isEndOpen}
              onEndClose={onEndClose}
            />
            <Container maxW="95%" h="350px" mt="20px">
              <GraphDisplay
                leafData={leafData}
                graphType={graphType}
                pourData={selectedPour}
              />
            </Container>
            <HStack p={2} w="full" justifyContent="center">
              <Menu>
                <MenuButton
                  px={4}
                  as={IconButton}
                  aria-label="Options"
                  variant="outline"
                >
                  {graphType === "conTemp"
                    ? "Concrete Temperature"
                    : "Ambient Temperature"}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setGraphType("conTemp");
                    }}
                  >
                    Concrete Temperature
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setGraphType("ambTemp");
                    }}
                  >
                    Ambient Temperature
                  </MenuItem>
                  <MenuItem>Strength </MenuItem>
                </MenuList>
              </Menu>
              {isLoading ? (
                <IconButton
                  aria-label="Options"
                  icon={<RepeatIcon />}
                  variant="outline"
                />
              ) : (
                <IconButton
                  aria-label="Options"
                  icon={<RepeatIcon />}
                  variant="outline"
                  onClick={handleRefresh}
                />
              )}
            </HStack>
            <VStack align="start" p={2} px={6}>
              <HStack
                py={1}
                px={2}
                borderRadius="6px"
                border="1px"
                borderStyle="solid"
                borderColor="gray.200"
                spacing={0}
              >
                <Tooltip
                  placement="right-start"
                  label={
                    Object.keys(selectedPour).length
                      ? ""
                      : "Select a pour from the menu"
                  }
                  aria-label="A tooltip"
                >
                  {/* Wrap in span as fa icons don't use forward ref*/}
                  <span>
                    <Icon as={MdSensors} boxSize="5" />
                  </span>
                </Tooltip>
                {Object.keys(selectedLeaf).length ? (
                  <Text pl={2}>{selectedLeaf.alias}</Text>
                ) : (
                  ""
                )}
              </HStack>
              <HStack
                py={1}
                px={2}
                borderRadius="6px"
                border="1px"
                borderStyle="solid"
                borderColor="gray.200"
                spacing={0}
              >
                <Tooltip
                  placement="right-start"
                  label={
                    Object.keys(selectedPour).length
                      ? ""
                      : "Select a pour from the menu"
                  }
                  aria-label="A tooltip"
                >
                  {/* Wrap in span as fa icons don't use forward ref*/}
                  <span>
                    <Icon as={FaCalendar} boxSize="5" />
                  </span>
                </Tooltip>
                {Object.keys(selectedLeaf).length ? (
                  <Text pl={2}>
                    {formatDate(selectedPour.startDate)}
                    {selectedPour.endDate
                      ? ` - ${formatDate(selectedPour.endDate)}`
                      : ""}
                  </Text>
                ) : (
                  ""
                )}
              </HStack>
            </VStack>
          </Container>
          <MobileMainNav />
        </VStack>
      </Box>
    </Container>
  );
};

export default MainPage;
