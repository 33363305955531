import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { useNavigate } from "react-router-dom"
import "./register.css"
import { useAuth } from "../../AuthContext"

const Register = () => {
  const { setIsAuthenticated } = useAuth

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [given_name, setGivenName] = useState("")
  const [family_name, setFamilyName] = useState("")
  const [error, setError] = useState("")
  const [isVerificationStage, setIsVerificationStage] = useState(false)
  const [verificationCode, setVerificationCode] = useState("")
  const [verificationStatus, setVerificationStatus] = useState(null)

  const navigate = useNavigate()

  const navigateToLogin = () => {
    navigate("/login")
  }

  const onSignUp = () => {
    setIsAuthenticated(true)
  }

  const signUp = async (event) => {
    event.preventDefault()
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          given_name,
          family_name,
        },
      })
      console.log(user)
      setIsVerificationStage(true)
    } catch (error) {
      setError(error.message)
    }
  }

  const confirmSignUp = async (event) => {
    event.preventDefault()
    try {
      await Auth.confirmSignUp(username, verificationCode)
      setVerificationStatus("success")
      if (onSignUp) {
        onSignUp()
      }
      // Redirect to login page after 3 seconds
      setTimeout(() => navigate("/login"), 3000) 
    } catch (error) {
      setError(error.message)
      setVerificationStatus("error")
    }
  }

  if (isVerificationStage) {
    return (
      <div className="verification-container">
        <h1 className="verification-title">Verify Email</h1>
        <form onSubmit={confirmSignUp} className="verification-form">
          <input
            className="verification-input"
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Verification Code"
          />
          <button className="verification-button" type="submit">
            Verify
          </button>
          {verificationStatus === "success" && (
            <p className="verification-success">
              Verification successful! Redirecting to login...
            </p>
          )}
          {verificationStatus === "error" && (
            <p className="verification-error">
              Verification failed. Please try again.
            </p>
          )}
        </form>
      </div>
    )
  }

  return (
    <form className="register-container" onSubmit={signUp}>
      <div className="register-form">
        <h1>Register for ARBOR Portal</h1>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <input
          type="text"
          value={given_name}
          onChange={(e) => setGivenName(e.target.value)}
          placeholder="First Name"
        />
        <input
          type="text"
          value={family_name}
          onChange={(e) => setFamilyName(e.target.value)}
          placeholder="Last Name"
        />
        <button type="submit">Register</button>
        <button
          type="button"
          onClick={navigateToLogin}
          className="back-to-login"
        >
          Back to Login
        </button>
        {error && <p>Error: {error}</p>}
      </div>
      <div className="register-image">
        <img src="/DSC_0306.jpg" alt="ARBOR in action" />
      </div>
    </form>
  )
}

export default Register

// import React, { useState } from 'react';
// import './register.css'; // registration style file
// import {Auth} from "aws-amplify"
// import { useAmplify } from '@aws-amplify/ui-react';

// const Register = ({onSignUp}) => {
//   //State for username and password
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [email, setEmail] = useState('');
//   const [error, setError] = useState(''); //state to store and display error
//   const [isVerificationStage, setIsVerificationStage] = useState(false);
//   const [verificationCode, setVerificationCode] = useState('');

//   const signUp = async (event) => {
//     event.preventDefault();
//     try {
//       const { user } = await Auth.signUp({
//         username,
//         password,
//         attributes: {
//           email, // Other attributes can also be included here
//         }
//       });
//       console.log(user);
//       setIsVerificationStage(true);//transition to the verification stage
//       } catch (error) {
//         setError(error.message);
//       }
//     //   onSignUp(); // Maybe change the UI to show a confirmation message or to log in
//     // } catch (error) {
//     //   setError(error.message);
//     // }
//     // setIsVerificationStage(true); // Add this line after successful registration
//   };

//   const confirmSignUp = async (event) => {
//     event.preventDefault();
//     try {
//       await Auth.confirmSignUp(username, verificationCode);
//       if(onSignUp) {
//         onSignUp();
//       }
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   if (isVerificationStage) {
//     return (
//       <div>
//         <h1>Verify Email</h1>
//         <form onSubmit={confirmSignUp}>
//           <input
//             type="text"
//             value={verificationCode}
//             onChange={(e) => setVerificationCode(e.target.value)}
//             placeholder="Verification Code"
//           />
//           <button type="submit">Verify</button>
//         </form>
//       </div>
//     );
//   }

//   return (
//     <form className="register-container" onSubmit={signUp}>
//       <div className="register-form">
//         <h1>Register for ARBOR Portal</h1>
//         <input
//           type="text"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//           placeholder="Username"
//         />
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="Email"
//         />
//         <input
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           placeholder="Password"
//         />
//         <button type="submit">Register</button>
//         {error && <p>Error: {error}</p>}
//       </div>
//       <div className="register-image">
//         <img src="/DSC_0306.jpg" alt="ARBOR in action" />
//       </div>
//     </form>
//   );

// };
// export default Register;
