/*
 * CreatePourModal.js
 * coop@arbor-iot.com
 * 2024-04-09
 * Modal component containing a form used to create a new pour
 */
import React, { useState } from "react"

import {
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react"

import { v4 as uuid } from "uuid"

// Services
import {
  getPoursByOwnerId,
  createPour,
} from "../../../services/PourInfoService.js"

// Helpers
import { filterTable } from "../../../utils/TableFilters.js"

const CreatePourModal = ({
  pours,
  leaves,
  ownerID,
  setPours,
  isNewOpen,
  onNewClose,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [propAlias, setPropAlias] = useState("")
  const [propProject, setPropProject] = useState("")
  const [propFloor, setPropFloor] = useState("")
  const [propLocation, setPropLocation] = useState("")
  const [propDate, setPropDate] = useState("")

  const handlePropAlias = (e) => {
    setPropAlias(e.target.value)
  }

  const handlePropProject = (e) => {
    setPropProject(e.target.value)
  }

  const handlePropFloor = (e) => {
    setPropFloor(e.target.value)
  }

  const handlePropLocation = (e) => {
    setPropLocation(e.target.value)
  }

  const handlePropDate = (e) => {
    setPropDate(e.target.value)
  }

  const handleAddProp = async () => {
    //FIX: check if props result in valid pour e.g. naming conventions and collisions

    if (Object.keys(leaves).length === 0) return
    if (!(propProject && propFloor && propLocation && propAlias && propDate))
      return

    // Check if pour with project floor and location exists
    const [hasPour] = filterTable(pours, {
      project: propProject,
      floor: propFloor,
      location: propLocation,
    })

    // Pour already exists
    if (hasPour) {
      return
    }

    const propDateObj = new Date(propDate)

    const [leaf] = filterTable(leaves, {
      alias: propAlias,
    })
    const tempPour = {
      leafDevEUI: leaf.devEUI,
      project: propProject,
      floor: propFloor,
      location: propLocation,
      pourID: uuid(),
      startDate: propDateObj.toISOString(),
      ownerID: ownerID,
    }

    setIsLoading(true)

    await createPour(tempPour)
    const response = await getPoursByOwnerId(ownerID)
    setPours(response)

    setPropAlias("")
    setPropProject("")
    setPropFloor("")
    setPropLocation("")
    setPropDate("")

    setIsLoading(false)
    onNewClose()
  }

  return (
    <Modal isOpen={isNewOpen} onClose={onNewClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Pour</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Device</FormLabel>
            <Select onChange={handlePropAlias} placeholder="Select a device">
              {leaves.map((leaf) => (
                <option key={leaf.alias} value={leaf.alias}>
                  {leaf.alias}
                </option>
              ))}
            </Select>

            <FormLabel id="project-label" pt={4}>Project</FormLabel>
            <Input id="project-input" value={propProject} onChange={handlePropProject} />

            <FormLabel id="floor-label" pt={4}>Floor</FormLabel>
            <Input id="floor-input" value={propFloor} onChange={handlePropFloor} />

            <FormLabel id="location-label" pt={4}>Location</FormLabel>
            <Input id="location-input" value={propLocation} onChange={handlePropLocation} />

            <FormLabel id="start-date-label" pt={4}>Start Date</FormLabel>
            <Input
              id="start-date-input"
              value={propDate}
              onChange={handlePropDate}
              placeholder="Select Date and Time"
              type="datetime-local"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            fontWeight="medium"
            mr={3}
            onClick={onNewClose}
          >
            Close
          </Button>
          {isLoading ? (
            <Button
              leftIcon={
                <Spinner
                  thickness="2px"
                  speed="1.0s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              }
              variant="ghost"
              fontWeight="medium"
            >
              Loading...
            </Button>
          ) : (
            <Button
              variant="ghost"
              fontWeight="medium"
              onClick={handleAddProp}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreatePourModal
