import { Auth } from "aws-amplify"

export const sortLeaves = (leaves) => {
  return leaves.sort((a, b) => {
    const aliasA = a.alias.toLowerCase()
    const aliasB = b.alias.toLowerCase()
    return aliasA.localeCompare(aliasB)
  })
}

export const sortPours = (pours) => {
  return pours.sort((r, l) => {
    if (r.project < l.project) {
      return -1
    }
    if (r.project > l.project) {
      return 1
    }

    if (r.floor < l.floor) {
      return -1
    }
    if (r.floor > l.floor) {
      return 1
    }

    if (r.location < l.location) {
      return -1
    }
    if (r.location > l.location) {
      return 1
    }

    return 0
  })
}

export const signOut = async (setIsAuthenticated) => {
  try {
    await Auth.signOut()
    setIsAuthenticated(false)
  } catch (error) {
    console.error("Error signing out: ", error)
  }
}

export function CuringTime({ pourTime }) {
  const currentTime = new Date()
  const diff = currentTime - new Date(pourTime) // Ensure pourTime is a Date object
  const hours = diff / 3600000 // Convert to hours
  const roundedHours = Math.round(hours * 10) / 10 // Round to one decimal place
  if (roundedHours < 0) {
    return "Invalid pour time"
  } else if (isNaN(roundedHours)) {
    return "-"
  } else {
    return `${roundedHours} hrs` // Return the formatted string
  }
}

export const formatDate = (dateString) => {
  const date = new Date(dateString)

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const monthIndex = date.getMonth()
  const day = date.getDate()
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  // Convert 24-hour format to 12-hour format
  const period = hours >= 12 ? "PM" : "AM"
  const formattedHours = hours % 12 || 12

  // Format the date string with full month name, day, year, and time
  return `${monthNames[monthIndex]} ${day}, ${year} ${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")}${period}`
}
