/*
 * LeafDynService.js
 * coop@arbor-iot.com
 * 2024-04-09
 * Provides utilities to connect with leaf data tables in DynamoDB
 */
import { Auth } from "aws-amplify";
import { DynamoDB } from "aws-sdk";

const getLeafData = async (pour) => {
  const credentials = await Auth.currentCredentials();
  const docClient = new DynamoDB.DocumentClient({
    region: "us-east-1",
    credentials: Auth.essentialCredentials(credentials),
  });

  let FilterExpression = "updatedAt >= :startDate";
  let ExpressionAttributeValues = {
    ":deviceID": pour.leafDevEUI,
    ":startDate": pour.startDate,
  };

  if (pour.endDate) {
    FilterExpression += " AND updatedAt < :endDate";
    ExpressionAttributeValues[":endDate"] = pour.endDate;
  }

  const params = {
    TableName: "SensorData",
    KeyConditionExpression: "deviceID = :deviceID",
    FilterExpression,
    ExpressionAttributeValues,
  };

  let allItems = [];
  let lastEvaluatedKey = null;

  do {
    try {
      const data = await docClient
        .query({ ...params, ExclusiveStartKey: lastEvaluatedKey })
        .promise();

      allItems = allItems.concat(data.Items);
      lastEvaluatedKey = data.LastEvaluatedKey;
    } catch (err) {
      console.error(err);
      throw new Error("Error querying DynamoDB");
    }
  } while (lastEvaluatedKey);
  return allItems;
};

export { getLeafData };
