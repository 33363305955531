/**
 * LeafInfoService.js
 * Author: Michael Bacani
 * Last Updated: 2024-04-10
 * All functions related to interacting with RDS
 */

import axios from "axios"

/**
 * Gets all Leaves with ownerID as its owner
 * @param {String} ownerID  Cognito user sub
 * @returns {Array}         Array of leaves related to the owner
 */
export const getLeavesByOwnerID = async (ownerID) => {
  try {
    const { data: response } = await axios.get("/leaf/filter", {
      params: { ownerID },
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Gets all leaves in the database
 * @param {function} setLeaves  useState setter for leaves
 * @returns {Array}             Array of leaves
 */
export const getLeaves = async (setLeaves) => {
  try {
    const { data: response } = await axios.get("/leaf/all")
    setLeaves(response)
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Updates devices owner ID
 * @param {String} devEUI   Device EUI
 * @param {String} ownerID  Cognito user sub
 * @returns
 */
export const updateDeviceOwner = async (devEUI, ownerID) => {
  try {
    const response = await axios.put("/leaf/", {
      devEUI: devEUI,
      ownerID: ownerID,
    })
    return
  } catch (error) {
    console.error("Error updating data: ", error)
    return error
  }
}

/**
 * Gets a single device with SN (sn) and Product Key (productKey) 
 * attributes
 * @param {Int}       sn          Devices serial number
 * @param {String}    productKey  Devices product key
 * @param {Function}  setDevice   useState hook setter
 * @returns
 */
export async function getDeviceBySN(sn, productKey, setDevice) {
  try {
    const response = await axios.get("/leaf/filter", {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        leafSN: sn,
        productKey: productKey,
      },
    })
    setDevice(response.data[0])
  } catch (error) {
    console.error("Error fetching data: ", error)
    return error
  }
}
