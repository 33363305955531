import { chakra, shouldForwardProp } from "@chakra-ui/react"
import { motion, isValidMotionProp } from "framer-motion"

const ChakraFramerDiv = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
})

const FadeIn = ({ children }) => {
  return (
    <ChakraFramerDiv
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      variants={{
        visible: { opacity: 1, x: 0, y: 0 },
        hidden: { opacity: 0, x: 0, y: 0 },
      }}
    >
      {children}
    </ChakraFramerDiv>
  )
}

const FadeInBottom = ({ children }) => {
  return (
    <ChakraFramerDiv
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      variants={{
        visible: { opacity: 1, x: 0, y: 0 },
        hidden: { opacity: 0, x: 0, y: 100 },
      }}
    >
      {children}
    </ChakraFramerDiv>
  )
}

const FadeInLeft = ({ children }) => {
  return (
    <ChakraFramerDiv
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      variants={{
        visible: { opacity: 1, x: 0, y: 0 },
        hidden: { opacity: 0, x: -100, y: 0 },
      }}
    >
      {children}
    </ChakraFramerDiv>
  )
}

const FadeInRight = ({ children }) => {
  return (
    <ChakraFramerDiv
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      variants={{
        visible: { opacity: 1, x: 0, y: 0 },
        hidden: { opacity: 0, x: 100, y: 0 },
      }}
    >
      {children}
    </ChakraFramerDiv>
  )
}

export { FadeIn, FadeInLeft, FadeInRight, FadeInBottom }
