// Helpers
const filterTable = (table, conditions) => {
  return table.filter((row) => {
    for (let key in conditions) {
      if (row[key] !== conditions[key]) {
        return false
      }
    }
    return true
  })
}

export { filterTable }
