/**
 * EndPourModal.js
 * Author: Michael Bacani
 * 2024-04-17
 * Modal component for end pour feature
 */

import { useState } from "react"

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  Container
} from "@chakra-ui/react"
import {
  updatePour,
  getPoursByOwnerId,
} from "../../../services/PourInfoService"
import { WarningIcon } from "@chakra-ui/icons"

const EndPourModal = ({
  selectedPour,
  setSelectedPour,
  ownerID,
  setPours,
  isEndOpen,
  onEndClose,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const selectPourToast = () => {
    toast({
      description: "Select a pour"
    })
  }

  const handleEndPour = () => {
    if (Object.keys(selectedPour).length === 0) return
    if (selectedPour.endDate) return

    const tempDate = new Date().toISOString()
    const tempPour = {
      ...selectedPour,
      endDate: tempDate,
    }

    setSelectedPour(tempPour)
    const setEndDate = async () => {
      try {
        setIsLoading(true)
        await updatePour(tempPour)
        const response = await getPoursByOwnerId(ownerID)
        setPours(response)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    setEndDate()
    onEndClose()
  }

  return (
    <Modal isOpen={isEndOpen} onClose={onEndClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody textAlign="center">
          <WarningIcon w={10} h={10} mt={3} />
          <Text fontSize="20px" mt={3} mb={3}>
            Are you sure you want to end the pour?
          </Text>
          <Text>
            This means any data from the pours sensor will no longer be
            associated with this pour and will free up the sensor to be used for
            another pour.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button mr={3} onClick={onEndClose}>
            Cancel
          </Button>
          {isLoading ? (
            <Button
              leftIcon={
                <Spinner/>
              }
              colorScheme="red"
            >
              End Pour
            </Button>
          ) : (
            <Button colorScheme="red" onClick={handleEndPour}>
              End Pour
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EndPourModal
